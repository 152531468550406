import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import axios from 'axios';
import "./Contact.css";
// import useForm from '../UseForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
// const FORM_ENDPOINT = "https://herotofu.com/start"; 
const API_ENDPOINT = 'https://console.spiderai.in/spider-enquiry';
// import "react-toastify/ReactToastify.css";
// import "react-toastify/dist/ReactToastify.css";

// toast.configure();



const Contact = () => {

  const initialFormData  = {
    company: '',
    name: '',
    email: '',
    mobile: '',
    message: '',
  
  }
  
const [formData, setFormData] = useState(initialFormData);

const navigate = useNavigate();


const handleChange = (event) => {
  const { id, value } = event.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [id]: value,
  }));
};

const notify = (type, message) => {
  if (type === "OK") {
    toast.success(message);
  } else if (type === "error") {
    toast.error(message);
  }
};
  

const validateForm = () => {
  const errors = [];

  if (!formData.company) {
    errors.push("Company Name");
  }
  if (!formData.name) {
    errors.push("Your Name");
  }
  if (!formData.email) {
    errors.push("Email Address");
  }
  if (!formData.mobile) {
    errors.push("Mobile Number");
  }
  if (!formData.message) {
    errors.push("Message");
  }
  // if (!formData.company && !formData.name && !formData.email && !formData.mobile && !formData.message) {
  //   errors.push("Required Field");
  // }
  

  return errors;
};

const handleSubmit = async (event) => {
  event.preventDefault();

  const emptyFields = validateForm();


  if (emptyFields.length > 0) {
    notify("error", `Please enter ${emptyFields.join(", ")}`);
    return;
  }

  try {
    const response = await axios.get(API_ENDPOINT, {
      params: formData,
    });
    console.log(response);
    console.log(response.data);
    // notify("success", "Form submitted successfully!");
    window.location.reload();

    notify("success", "Form submitted successfully!");
    
    // Reset the form data
    setFormData({
      company: '',
      name: '',
      email: '',
      mobile: '',
      message: '',
    });


    // navigate("/contact")
    // Handle success here
  } catch (error) {
    console.error('Error submitting form:', error);
    notify("error", "Failed to submit form. Please try again later.");

    // Handle error here
  }
};


// const notify = () => {
//   // Calling toast method by passing string
//   toast.success("successful");
// };


  // const formElement = useRef(null);
  // const additionalData = {
  //   sent: new Date().toISOString(),
  // };

  // const { handleSubmit, status, message } = useForm({
  //   additionalData,
  // });

  //   const handleFormSubmit = (event) => {
  //   event.preventDefault();
  //   const formData = new FormData(formElement.current);
  //   const formDataObject = Object.fromEntries(formData.entries());
  //   console.log('Form Data:', formDataObject);
  //   handleSubmit(event);
  // };

//   const handleFormSubmit = (event) => {
//     event.preventDefault();
//     const formData = new FormData(formElement.current);
//     const formDataObject = Object.fromEntries(formData.entries());
//     console.log('Submitted Form Data:');
//     console.log('Name:', formDataObject['firstName'] + ' ' + formDataObject['lastName']);
//     console.log('Email:', formDataObject['email']);
//     console.log('Contact:', formDataObject['contact']);
//     console.log('Company:', formDataObject['company']);
//     console.log('Message:', formDataObject['message']);
//     handleSubmit(event);
// };



  // if (status === "success") {
  //   return (
  //     <div>
  //       <div>Thank you!</div>
  //       <div>{message}</div>
  //     </div>
  //   );
  // }

  // if (status === "error") {
  //   return (
  //     <div>
  //       <div>Something bad happened!</div>
  //       <div>{message}</div>
  //     </div>
  //   );
  // }


  
  return (
    <>
      <section className='contact-sec1-set pt-md-0 px-md-5  py-5'>
        <div className='container-fluid'>
          <div className='row p-lg-5 p-1 align-items-center justify-content-center'>
            <div className='col-lg-5 mb-5 mb-md-0'>
              <div className='col-lg-12 mb-5 text-align'>
                <h1 className='fw-bold fs-3rem mt-5 mb-4'>Let's Connect</h1>
                <p className='fs-3 mb-5'>Connecting with Confidence: Reach Out and Let's Make Things Happen Together.</p>
              </div>
              <div className='col-lg-12'>
                <div className='row contact-detail-set mt-4'>
                  <div className='col-lg-1 col-md-2 col-sm-2'>
                  <img className='contact-logo-set mt-1 mb-4' src='assets/images/contact/call-logo.png' alt='Call Logo' />
                  </div>
                  <div className='col-lg-10 col-md-10  mb-3'>
                  <p className='mb-0 fs-4 mb-1 text-light fw-bold'>CALL:</p>
                  <a href='tel:+919099004645' style={{textDecoration:"none", color:"inherit"}}>
                  <p className='fs-num-set mb-2'>+91 90990 04645</p>
                  </a>
                  </div>
                </div>
                <div className='row contact-detail-set'>
                  <div className='col-lg-1 col-md-2 col-sm-2'>
                    <img className='contact-logo-set mt-1 mb-4' src='assets/images/contact/Email-logo.png' alt='Email Logo' />
                  </div>
                  <div className='col-lg-10 col-md-10  mb-3'>
                    <p className='mb-0 fs-4 mb-1 text-light fw-bold'>EMAIL:</p>
                    <a href='mailto:contact@sankhyanktechsolutions.com' target='_blank' style={{textDecoration:"none", color:"inherit"}}>
                    <p className='fs-num-set mb-2 '>contact@sankhyanktechsolutions.com</p>
                    </a>
                  </div>
                </div>
                <div className='row contact-detail-set'>
                  <div className='col-lg-1 col-md-2 col-sm-2'>
                    <img className='contact-logo-set mt-1 mb-4' src='assets/images/contact/location-logo.png' alt='Location Logo' />
                  </div>
                  <div className='col-lg-10 col-md-10  mb-3'>
                    <p className='mb-0 fs-4 mb-1 text-light fw-bold'>LOCATION:</p>
                    <p className='fs-num-set mb-2'>908 - Capitol Icon, Sargasan, Gandhinagar, Gujarat.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 mt-lg-0 mt-md-5 d-none d-sm-block d-md-none d-lg-block'>
              <div>
                <img className="contact-banner-set text-end" src='assets/images/contact/contact banner (5).jpg' alt='Contact Banner' />
              </div>
            </div>
            <div className='col-lg-6 mt-lg-0 mt-md-5 d-block d-sm-none d-md-block d-lg-none mb-5'>
            <div>
              <img className="contact-banner-set text-end mb-4" src='assets/images/contact/contact-banner-mobile.jpg' alt='Contact Banner' />
            </div>
          </div>
          </div>
        </div>
      </section>


      <section className='contact-sec2-set  p-md-5 p-md-4'>
        <div className='container-fluid'>
          <div className='row p-md-5 py-5 mx-4 align-items-center justify-content-between'>
            <div className='col-lg-5 '>
              <div>
                <h1 className='fw-bold  mb-4 ' style={{ color: '#b7acac85' }}>Say Hello</h1>
                <h1 className='fw-bold mb-4 '>Let's initiate your
                  project repository</h1>
                <p className=' mb-4' style={{fontSize:"19px"}}>Drop us a line and one of our techies will
                  be in touch with you</p>
              </div>
            </div>
            <div className='col-lg-6 mt-4'>
            {/*
            ref={formElement}  action={FORM_ENDPOINT}
            onSubmit={handleFormSubmit}
            method="POST"

            onSubmit={handleFormSubmit} id='contactForm'
          */}
              <form onSubmit={handleSubmit} >
              <div className='row'>
              <div className='col-lg-6'>
                <div class="form-floating mb-3">
                  <input type="text "  id="company"  name='company'  autocomplete="off"
                  className="form-control border-r input-bg-set"  onChange={handleChange}
                  placeholder="company" />
                  <label className='set-label ' for="floatingInput">Company Name
                  </label>
                </div>
              </div>
              <div className='col-lg-6'>
                <div class="form-floating mb-3">
                  <input type="text "  id="name"  name='name'    onChange={handleChange}  autocomplete="off"
                  className="form-control border-r input-bg-set"  placeholder="name" />
                  <label className='set-label ' for="floatingInput">Your Name
                  </label>
                </div>
              </div>

                <div className='col-lg-12'>
                  <div class="form-floating mb-3">
                    <input type="email "  id="email" name='email'      onChange={handleChange}  autocomplete="off"
                    className="form-control border-r input-bg-set"  placeholder="name@example.com" />
                    <label className='set-label ' for="floatingInput">Email Address</label>
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div class="form-floating mb-3">
                    <input type="number" id="mobile"  name='mobile'     onChange={handleChange}  autocomplete="off"
                    className="form-control border-r input-bg-set"  placeholder="Phone" />
                    <label className='set-label ' for="floatingPassword">Mobile Number</label>
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div class="form-floating mb-3">
                  <textarea type="message" id="message"  name='message'       onChange={handleChange}  autocomplete="off"
                  className='form-control border-r py-5 set-text-area input-bg-set'  placeholder="Meassage" ></textarea>
                    <label className='set-label ' for="floatingInput">Message</label>
                  </div>
                </div>
                <div className='col-lg-12 mb-4'>
                <div class="d-grid gap-2">

                <button
                type="submit"
                className="btn btn-primary fs-4"
                style={{ height: '54px', backgroundColor: '#c5bfbf', color: 'black', border: 'none' }}
              >
                Submit
              </button>
              <ToastContainer />

        
              {/*
              {status === 'loading' && <p>Loading...</p>}
              {status === 'success' && <p>Form submitted successfully!</p>}
              {status === 'error' && <p>Failed to submit form. Please try again later.</p>}
                {status !== 'loading' && (
                  
                  <button type="button" class="btn btn-primary fs-4" style={{height:'54px', backgroundColor:"#c5bfbf",color:"black", border:"none"}} >Submit</button>
                */}
                {/* Show loading message while submitting
              )}
              {status === 'loading' && <p>Loading...</p>}
              
              {status === 'success' && <p>Form submitted successfully!</p>}
              {status === 'error' && <p>Failed to submit form. Please try again later.</p>}
            */}
                {/*
            */}
              </div>
                </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Contact;
