import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import "../Header/Header.css"
import $ from 'jquery'
import { useEffect , useState} from 'react'

const Header = () => {

  const [activeLink, setActiveLink] = useState('');

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  
  useEffect(() => {
    const handleNavCollapse = () => {
      const navbarCollapse = document.querySelector("#navbarSupportedContent");
      
      if (navbarCollapse.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }
    };

    const navLinks = document.querySelectorAll(".nav-link");

    navLinks.forEach(link => {
      link.addEventListener("click", handleNavCollapse);
    });

    return () => {
      navLinks.forEach(link => {
        link.removeEventListener("click", handleNavCollapse);
      });
    };
  }, []);
  

  return (
   <div className='conatiner sticky-top '>
  <nav className="navbar  navbar-expand-lg  border-body  " data-bs-theme="dark">
    <div className="container-fluid px-6">
      <Link to="/" className="navbar-brand nav-link " aria-current="page" > <img src="assets/images/STS logo (1).png" className='img' alt='logo'  /></Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div  className="collapse navbar-collapse"   id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0  px-4 ">
          <li className="nav-item mx-3">

            <Link to="/" className="nav-link mt-lg-0  mt-5 fs-mobile-set " activeStyle={{color:"#FF851B"}}  data-toggle="collapse" data-target=".navbar-collapse.hide" aria-current="page" activeClassName="active"> HOME</Link>
          </li>
          <li className="nav-item mx-3">
            <Link to="/about" className="nav-link  mt-lg-0  mt-4  fs-mobile-set" activeStyle={{color:"#FF851B"}} data-toggle="collapse" data-target=".navbar-collapse.hide"   activeClassName="active">ABOUT</Link>
          </li>
          <li className="nav-item mx-3">
            <Link to="/service" className="nav-link mt-lg-0  mt-4  fs-mobile-set  " activeStyle={{color:"#FF851B"}}  data-toggle="collapse" data-target=".navbar-collapse.hide" aria-disabled="true" activeClassName="active">SERVICES</Link>
          </li>
          <li className="nav-item mx-3">
            <Link to="/product" className="nav-link mt-lg-0  mt-4  fs-mobile-set  " activeStyle={{color:"#FF851B"}} data-toggle="collapse" data-target=".navbar-collapse.hide" aria-disabled="true" activeClassName="active">PRODUCT / STARTUP</Link>
          </li>
          <li className="nav-item mx-3">
            <Link to="/contact" className="nav-link  mt-lg-0  mt-4 fs-mobile-set " activeStyle={{color:"#FF851B"}} data-toggle="collapse" data-target=".navbar-collapse.hide" aria-disabled="true" activeClassName="active">CONTACT</Link>
          </li>
        </ul>
        <div className='ml-5 mt-5 mb-5 d-flex justify-content-center'>
        <ul class="list-inline footer-links d-block d-sm-none d-md-block d-lg-none mb-5   mt-md-0  mt-4" style={{marginLeft:"-2.5rem", marginLeft:"inherit"}}>
        <li class="list-inline-item">
          <a href="#" class="text-white  mx-4">
          <a href='https://www.instagram.com/sankhyanktechsolutions/' target='_blank' style={{textDecoration:"none", color:"inherit"}}>
            <img className='ing-logo' src='assets/images/parter-img/insta-icon.png' alt='insta' />
            </a>
          </a>
        </li>
        <li class="list-inline-item">
          <a href="#" class="text-white mx-4">
          <a href='https://www.facebook.com/sankhyanktechsolutions/videos' target='_blank' style={{textDecoration:"none", color:"inherit"}}>
            <img className='ing-logo' src='assets/images/parter-img/fb-icon.png' alt='fb' />
            </a>
          </a>
        </li>
        <li class="list-inline-item">
        <a href="#" class="text-white mx-4">
        <a href='https://www.youtube.com/watch?v=SaZymZuv-WQ' target='_blank' style={{textDecoration:"none", color:"inherit"}}>
        <img className='ing-logo' src='assets/images/parter-img/yt-icon.png' alt='yt' />
        </a>
        </a>
        </li>
        </ul>
        </div>

        {/*
        <form className="d-flex" role="search">
        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
        <button className="btn btn-outline-success" type="submit">Search</button>
        </form>
    */}
      </div>
    </div>
  </nav>
</div>


  )
}

export default Header