import React from 'react'
import Slider from "react-slick";
import { useRef, useState, useEffect } from 'react';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import "../Product/Product.css"


import './Product.css'

const Product = () => {
  const [transformStyle, setTransformStyle] = useState('');

  const sliderRef = useRef(null);
  const { hash } = useLocation();
  const containerRef = useRef(null);
  const innerRef = useRef(null);

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    arrows: false

    // autoplaySpeed: 1000,
  };


  useEffect(() => {
    const container = containerRef.current;
    const inner = innerRef.current;

    const mouse = {
      _x: 0,
      _y: 0,
      x: 0,
      y: 0,
      updatePosition: function (event) {
        const e = event || window.event;
        this.x = (e.clientX - this._x) / 4; // Divide by a smaller factor for decreased movement
        this.y = (e.clientY - this._y) * -1 / 4; // Divide by a smaller factor for decreased movement
      },
      setOrigin: function (e) {
        this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
        this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
      }
    };

    const updateRate = 10; // Increase the update rate for decreased sensitivity
    let counter = 0;

    const isTimeToUpdate = () => {
      return counter++ % updateRate === 0;
    };

    const onMouseEnterHandler = (event) => {
      update(event);
    };

    const onMouseLeaveHandler = () => {
      inner.style = "";
    };

    const onMouseMoveHandler = (event) => {
      if (isTimeToUpdate()) {
        update(event);
      }
    };

    const update = (event) => {
      mouse.updatePosition(event);
      const centerX = inner.offsetWidth / 2;
      const distanceFromCenter = event.clientX - centerX;
      const normalizedDistance = distanceFromCenter / centerX;
      const invertedDistance = normalizedDistance * -1;
      updateTransformStyle(invertedDistance * 0.10); // Adjust the multiplier and divisor to reduce movement
    };


    const updateTransformStyle = (x) => {
      const style = `rotateY(${x}deg)`;
      inner.style.transform = style;
      inner.style.webkitTransform = style;
      inner.style.mozTransform = style;
      inner.style.msTransform = style;
      inner.style.oTransform = style;
    };


    if (container && inner) {
      mouse.setOrigin(container);
      container.addEventListener("mouseenter", onMouseEnterHandler);
      container.addEventListener("mouseleave", onMouseLeaveHandler);
      container.addEventListener("mousemove", onMouseMoveHandler);

      return () => {
        container.removeEventListener("mouseenter", onMouseEnterHandler);
        container.removeEventListener("mouseleave", onMouseLeaveHandler);
        container.removeEventListener("mousemove", onMouseMoveHandler);
      };
    }
  }, []);

  useEffect(() => {
    const cursor = document.querySelector('#mousecursor');
    const section = document.querySelector('#sectionToTrackMouseMovement');
    const mouse = { x: 300, y: 300 };
    const pos = { x: 0, y: 0 };
    const speed = 0.1; // between 0 and 1

    const updatePosition = () => {
      pos.x += (mouse.x - pos.x) * speed;
      pos.y += (mouse.y - pos.y) * speed;
      cursor.style.left = pos.x + 'px';
      cursor.style.top = pos.y + 'px';
    };

    const isInsideSection = (clientX, clientY) => {
      const sectionRect = section.getBoundingClientRect();
      return (
        clientX >= sectionRect.left &&
        clientX <= sectionRect.right &&
        clientY >= sectionRect.top &&
        clientY <= sectionRect.bottom
      );
    };

    const handleMouseMove = (e) => {
      const clientX = e.clientX;
      const clientY = e.clientY;
      if (isInsideSection(clientX, clientY)) {
        cursor.style.display = 'block'; // Show cursor when inside section
        const sectionRect = section.getBoundingClientRect();
        mouse.x = clientX - sectionRect.left;
        mouse.y = clientY - sectionRect.top;
      } else {
        cursor.style.display = 'none'; // Hide cursor when outside section
      }
    };

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const clientX = touch.clientX;
      const clientY = touch.clientY;
      if (isInsideSection(clientX, clientY)) {
        cursor.style.display = 'block'; // Show cursor when inside section
        const sectionRect = section.getBoundingClientRect();
        mouse.x = clientX - sectionRect.left;
        mouse.y = clientY - sectionRect.top;
      } else {
        cursor.style.display = 'none'; // Hide cursor when outside section
      }
    };

    section.addEventListener('mousemove', handleMouseMove);
    section.addEventListener('touchmove', handleTouchMove);

    function loop() {
      updatePosition();
      requestAnimationFrame(loop);
    }

    requestAnimationFrame(loop);

    return () => {
      section.removeEventListener('mousemove', handleMouseMove);
      section.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);
  //   useEffect(()=>{
  //     /* Store the element in el */
  // let el = document.getElementById('tilt')

  // /* Get the height and width of the element */
  // const height = el.clientHeight
  // const width = el.clientWidth

  // /*
  //   * Add a listener for mousemove event
  //   * Which will trigger function 'handleMove'
  //   * On mousemove
  //   */
  // el.addEventListener('mousemove', handleMove)

  // /* Define function a */
  // function handleMove(e) {
  //   /*
  //     * Get position of mouse cursor
  //     * With respect to the element
  //     * On mouseover
  //     */
  //   /* Store the x position */
  //   const xVal = e.layerX
  //   /* Store the y position */
  //   const yVal = e.layerY

  //   /*
  //     * Calculate rotation valuee along the Y-axis
  //     * Here the multiplier 20 is to
  //     * Control the rotation
  //     * You can change the value and see the results
  //     */
  //   const yRotation = 10 * ((xVal - width / 2) / width)

  //   /* Calculate the rotation along the X-axis */
  //   const xRotation = -10 * ((yVal - height / 2) / height)

  //   /* Generate string for CSS transform property */
  //   const string = 'perspective(1200px) scale(1) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'

  //   /* Apply the calculated transformation */
  //   el.style.transform = string
  // }

  // /* Add listener for mouseout event, remove the rotation */
  // el.addEventListener('mouseout', function() {
  //   el.style.transform = 'perspective(200px) scale(1) rotateX(0) rotateY(0)'
  // })

  // /* Add listener for mousedown event, to simulate click */
  // el.addEventListener('mousedown', function() {
  //   el.style.transform = 'perspective(200px) scale(0.9) rotateX(0) rotateY(0)'
  // })

  // /* Add listener for mouseup, simulate release of mouse click */
  // el.addEventListener('mouseup', function() {
  //   el.style.transform = 'perspective(200px) scale(1.1) rotateX(0) rotateY(0)'
  // })
  //   })

  // useEffect(() => {
  //   const el = document.getElementById("tilt");
  //   // const el = document.querySelectorAll('#tilt');

  //   // alert(el);
  //   // var el = document.getElementById('spider');



  //   const handleMove = (e) => {
  //     const rect = el.getBoundingClientRect();
  //     const xVal = e.clientX - rect.left;
  //     const yVal = e.clientY - rect.top;

  //     const height = el.clientHeight;
  //     const width = el.clientWidth;

  //     const yRotation = 20 * ((xVal - width / 2) / width);
  //     const xRotation = -20 * ((yVal - height / 2) / height);

  //     const string = `perspective(500px) scale(1) rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;

  //     setTransformStyle(string);
  //   };

  //   const handleMouseOut = () => {
  //     setTransformStyle('perspective(500px) scale(1) rotateX(0) rotateY(0)');
  //   };

  //   const handleMouseDown = () => {
  //     setTransformStyle('perspective(500px) scale(0.9) rotateX(0) rotateY(0)');
  //   };

  //   const handleMouseUp = () => {
  //     setTransformStyle('perspective(500px) scale(1.1) rotateX(0) rotateY(0)');
  //   };

  //   el.addEventListener('mousemove', handleMove);
  //   el.addEventListener('mouseout', handleMouseOut);
  //   el.addEventListener('mousedown', handleMouseDown);
  //   el.addEventListener('mouseup', handleMouseUp);

  //   // Cleanup
  //   return () => {
  //     el.removeEventListener('mousemove', handleMove);
  //     el.removeEventListener('mouseout', handleMouseOut);
  //     el.removeEventListener('mousedown', handleMouseDown);
  //     el.removeEventListener('mouseup', handleMouseUp);
  //   };
  // }, []);



  return (
    <>

      <section className=''>
        <div className='container-fluid p-0'>
          <div className='img-container'>
            <div className='product-content'>
              <h4 className='fw-bold  fs-58rem'>Launch your startup
              </h4>
              <p className=" p-set-20px text-light mt-3">Building tech products for technical or non-technical founders. Kickstart your
                tech product with the right advice from our engineering teams.</p>

            </div>

            <video autoPlay muted loop className='productVideo d-none d-sm-block d-md-block d-lg-block' id='desktopVideo'>
              <source class="product-img-set" src='assets/images/product/product banner.mp4' type='video/mp4' />
            </video>
            <video autoPlay muted loop className='productVideo d-block d-lg-none d-md-none d-sm-none' id='mobileVideo'>
              <source src='assets/images/product/product banner mobile.mp4' type='video/mp4' />
            </video>

            {/*
          
          <div className='myImg d-none d-lg-block d-md-block d-sm-block'>
          <img class="product-img-set" src="assets/images/about/About-img.jpg" alt="About Image" />
          </div>
          <div className='myImg d-block d-lg-none d-md-none d-sm-none'>
          <img class="product-img-set" src="assets/images/about/About-mobile.jpg" alt="About Image" />
          </div>
        */}

          </div>
        </div>
      </section>





      <section className='' style={{ backgroundColor: "#003527" }} id='sectionToTrackMouseMovement' >

        <div className='container-fluid' id='spiderai' >
          {/**/}
          <div className='row justify-content-center no-guttors py-5'>
            <div className='col-lg-1 col-md-1'>
              <div className='division d-none d-sm-block d-md-block d-lg-block'>
                <h5 className='set-product-header fw-bold'> PRODUCT / STARTUP</h5>
              </div>
            </div>

            <div className='col-lg-11 col-md-11 pl-26ps'>

              <Slider {...settings}>
                <div className=''>

                  <div className='row d-flex call-kar ' id='call-kar' >
                    <div className='col-lg-5 mb-5  d-block d-sm-none d-md-block d-lg-none'>
                      <div >
                        <h1 className='color mb-4 fw-bold fs-35px' >Call Kar</h1>
                        <div className='text-center'>
                          <img className='web-product-img-set' src='assets/images/product/Call Kar.jpg' />
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-6 ' >

                      <div className='mt-3'  >

                        <p className='text-light fs-3 fw-bold' >"Call Kar - Your Ultimate Connection to Seamless Solutions"
                        </p>
                        <p className=' p-size-22px text-light'><span className='fs-3' style={{ letterSpacing: "2px" }}>W</span>elcome to Call Kar, your trusted partner for prompt assistance and personalized support tailored to address your every need. Whether you're seeking technical assistance, professional advice, or simply a friendly ear to listen, we're here to provide the seamless solutions you deserve.

                        </p>
                        <p className=' p-size-22px text-light'>We offer a range of services to cater to your diverse needs. Firstly, our technical support team stands ready to assist you with any issues you may encounter with your devices or software. Our skilled technicians guide you through troubleshooting steps and offer solutions to resolve any problems effectively. Secondly, our customer service representatives are available to answer your questions and concerns about products or services with prompt and helpful responses. Additionally, our experienced consultants provide expert advice and personalized support on various topics to help you make informed decisions and achieve your goals. Lastly, in emergencies or urgent situations, our team is equipped to provide immediate assistance and support when you need it most.

                        </p>
                        <p className=' p-size-22px text-light'>In conclusion, Call Kar is your go-to partner for all your assistance needs, offering a comprehensive range of services from technical support to emergency assistance. With our commitment to promptness, personalized service, professionalism, and reliability, we strive to exceed your expectations and ensure a satisfying experience. Choose Call Kar for seamless solutions tailored to your unique requirements, and let us assist you in achieving your goals with confidence and peace of mind.


                        </p>


                      </div>
                    </div>
                    {/*
                */}
                    <div className='col-lg-5  d-none d-sm-block d-md-none d-lg-block '    >
                      <div >
                        <p className='color mb-4 fw-bold fs-3px'>Call Kar</p>
                        <div className='text-center' >
                          {/*
                  <div id='tilt' style={{ transform: transformStyle }}>
</div>
*/}
                          <img className='web-product-img-set rounded-4 ' src='assets/images/product/Call Kar.jpg' />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>





                <div className='row d-flex spider-ai slick-dots-product' id='spider-ai'>
                  <div className='col-lg-5 mb-5  d-block d-sm-none d-md-block d-lg-none' >
                    <div>
                      <h1 className='color mb-4 fw-bold fs-35rem'>Spider Ai</h1>
                      <div className='text-center'>
                        <img className='web-product-img-set' src='assets/images/product/Spider ai.jpg' />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 ' >

                    <div className='mt-3'>

                      <p className='  clr-white fs-3 fw-bold'>"SpiderAI - Growing with Google"

                      </p>
                      <p className='p-size-22px text-light'><span className='fs-3' style={{ letterSpacing: "2px" }}>I</span>ntroducing SpiderAI, the latest advancement in SEO (Search Engine Optimization) technology, designed to propel your online presence to new heights. Developed in collaboration with Google, SpiderAI harnesses the power of cutting-edge algorithms and machine learning to optimize your website's performance in search engine rankings.

                      </p>
                      <p className='p-size-22px text-light'>With SpiderAI, you can expect unparalleled precision and efficiency in your SEO strategy. Our intelligent system continuously analyzes search engine algorithms and user behavior patterns to ensure that your website stays ahead of the competition. From keyword optimization to content relevance, SpiderAI provides actionable insights and recommendations to enhance your website's visibility and drive organic traffic.

                      </p>
                      <p className='p-size-22px text-light'>Say goodbye to guesswork and manual optimizations. SpiderAI automates the SEO process, saving you time and resources while delivering measurable results. Whether you're a small business looking to increase online visibility or a digital marketer seeking to maximize ROI, SpiderAI is your trusted partner in achieving SEO success.
                      </p>
                      <p className='p-size-22px text-light'>Experience the future of SEO with SpiderAI - Growing with Google. Unlock the full potential of your website and reach new heights in search engine rankings. Join the revolution and watch your online presence soar with SpiderAI.</p>

             
                    {/*
                  */}
             

                    <div className='d-flex mt-4 mb-3'>

                    <small  className="set-small fw-inherit">@</small>
                    <a href='https://spiderai.in/' className='website-link spiderai-set fw-bold'  target='_blank' >SpiderAI</a>
                    </div>



                  </div>
                  </div>
                  <div className='col-lg-5  d-none d-sm-block d-md-none d-lg-block' >
                    <div>
                      <p className='color mb-4 fw-bold fs-3px'>Spider AI</p>
                      <div className='text-center'>
                        {/*
                      <div id="spider" style={{ transform: transformStyle }}>
                      </div>
                    */}
                        <img className='web-product-img-set rounded-4' src='assets/images/product/Spider ai.jpg' />
                      </div>
                    </div>
                  </div>

                </div>

                <div className='row d-flex just-repair-it' id='just-repair-it'>
                  <div className='col-lg-5 mb-5  d-block d-sm-none d-md-block d-lg-none '>
                    <div>
                      <h1 className='color mb-4 fw-bold fs-35rem'>Just Repair It</h1>
                      <div className='text-center'>
                        <img className='web-product-img-set' src='assets/images/product/JustRepairIT.jpg' />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 '>

                    <div className='mt-3'>
                      <p className=' clr-white fs-3 fw-bold'>"JustRepairIT - Swift Solutions for All Your Device Woes"</p>
                      <p className=' p-size-22px text-light'><span className='fs-3' style={{ letterSpacing: "2px" }}>W</span>e offer a wide range of specialized repair services tailored to meet your device needs. Our smartphone repair covers everything from cracked screens to battery issues, ensuring your device functions optimally. For tablets, our team handles repairs for all major brands, addressing issues like broken displays or malfunctioning batteries. Our laptop repair services encompass hardware upgrades, software troubleshooting, and more, tackling problems like broken keyboards, faulty trackpads, or virus infections. Additionally, our desktop repair solutions diagnose and fix slow performance or startup failures, providing both hardware replacements and software optimizations. Lastly, our data recovery services are designed to retrieve your valuable data quickly and securely in cases of accidental deletion or hardware failures.

                      </p>
                      <p className=' p-size-22px text-light'>There are several compelling reasons to choose JustRepairIT for your tech repair needs. Our team consists of expert technicians with years of experience in diagnosing and repairing a wide variety of device issues. We prioritize swift turnaround times, understanding the importance of getting your device back to you as quickly as possible. We use only high-quality replacement parts to ensure your device performs like new after repairs. Most importantly, customer satisfaction is our top priority, and we work tirelessly to exceed your expectations with exceptional service at every step.

                      </p>

                      <p className=' p-size-22px text-light'>At JustRepairIT, we believe in delivering top-notch quality in every repair service we offer. From using quality replacement parts to employing skilled technicians and ensuring prompt service, we are committed to excellence. Our focus is on providing reliable and long-lasting solutions to ensure your devices run smoothly and efficiently.

                      </p>



                    </div>
                  </div>
                  <div className='col-lg-5  d-none d-sm-block d-md-none d-lg-block '>
                    <div>
                      <p className='color mb-4 fw-bold fs-3px'>JustRepairIT</p>
                      <div className='text-center'>
                        <img className='web-product-img-set  rounded-4' src='assets/images/product/JustRepairIT.jpg' />
                      </div>
                    </div>
                  </div>

                </div>

                <div className='row d-flex khana' id='khana'>
                  <div className='col-lg-5 mb-5  d-block d-sm-none d-md-block d-lg-none '>
                    <div>
                      <h1 className='color mb-4 fw-bold fs-35rem'>Khaas Khana</h1>
                      <div className='text-center'>
                        <img className='web-product-img-set' src='assets/images/product/Khaas Khana.jpg' />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 '>

                    <div className='mt-3'>
                      <p className=' clr-white fs-3 fw-bold'>"Khaas Khana - Elevating Products to Memorable Experiences"
                      </p>
                      <p className=' p-size-22px text-light'><span className='fs-3' style={{ letterSpacing: "2px" }}>W</span>elcome to Khaas Khana, where we specialize in transforming ordinary products into extraordinary experiences. With our passion for innovation and dedication to quality, we craft unique offerings that resonate with discerning tastes and preferences, delighting customers and creating lasting memories.</p>
                      <p className='p-size-22px text-light'>At Khaas Khana, we specialize in turning ordinary products into extraordinary experiences. Our mission is simple: to craft unique offerings that captivate discerning tastes and preferences. From gourmet foods to luxury goods, we go beyond the ordinary to create experiences that resonate with our customers on a profound level.
                      </p>
                      <p className='p-size-22px text-light'>Through meticulous attention to detail and a commitment to quality, we transform everyday items into something truly exceptional. Whether it's a handcrafted delicacy, a thoughtfully curated gift basket, or a bespoke product tailored to individual preferences, each creation from Khaas Khana is designed to leave a lasting impression.
                      </p>
                      <p className='p-size-22px text-light'>Choosing Khaas Khana means choosing memorable experiences, quality assurance, and personalized service. We believe that food is more than just sustenance—it's an experience. With Khaas Khana, you can expect to be transported to a world of flavor, creativity, and delight with every bite. We stand behind the quality of our products and are dedicated to making things right if you're not completely satisfied. Our team is always ready to offer personalized recommendations and assistance to ensure you find exactly what you're looking for.

                      </p>
                      <div className='d-flex mt-4 mb-3'>

                      <small  className="set-small fw-inherit">@</small><a href='https://khaaskhaana.com/' className='website-link spiderai-set fw-bold'  target='_blank' >Khass Khana</a>
                      </div>
  


                    </div>
                  </div>
                  <div className='col-lg-5   d-none d-sm-block d-md-none d-lg-block  '>
                    <div>
                      <p className='color mb-4 fw-bold fs-3px'>Khaas Khana</p>
                      <div className='text-center'>
                        <img className='web-product-img-set  rounded-4' src='assets/images/product/Khaas Khana.jpg' />
                      </div>
                    </div>
                  </div>

                </div>

                {/*

              <div className='row d-flex'>
              <div className='col-lg-6 '>

                <div className='mt-2'>
                  <p className='fs-3 clr-white'><p className=" mb-4">"Building Tomorrow's Digital Experiences Today"</p>
                  </p>
                  <p className=' p-size-22px'>Website development involves the creation and maintenance of websites. It encompasses various tasks such as designing the layout and appearance, coding functionality, and ensuring usability and performance. Website development typically includes front-end development, focusing on the visible parts of the site that users interact with, and back-end development, which involves server-side scripting and database management. The goal of website development is to create a visually appealing, user-friendly, and functional website that meets the needs of its target audience. It involves a combination of technical skills, creativity, and attention to detail to bring a website to life on the internet.</p>
                 


                </div>
              </div>
              <div className='col-lg-5 '>
                <div>
                  <h1 className='color mb-4 fw-bold fs-3rem'>WEB DEVELOPMENT</h1>
                  <div className='text-center'>
                  <img className='web-product-img-set' src='assets/images/product/web-dev.jpg' />
                  </div>
                </div>
              </div>

            </div>

            */}


                <div className='row d-flex crm' id='crm'>
                  <div className='col-lg-5 mb-5  d-block d-sm-none d-md-block d-lg-none'>
                    <div>
                      <h1 className='color mb-4 fw-bold fs-35rem'>CRM for All Services</h1>
                      <div className='text-center'>
                        <img className='web-product-img-set' src='assets/images/product/CRM for All Services.jpg' />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6  '>

                    <div className='mt-3'>
                      <p className=' clr-white fs-3 fw-bold'>"CRM For All Services - Revolutionizing Customer Relationship Management"</p>
                      <p className=' text-light p-size-22px'><span className='fs-3' style={{ letterSpacing: "2px" }}>W</span>elcome to CRM for All Services, where we're revolutionizing customer relationship management to optimize client interactions and drive business growth. With our tailored solutions and innovative approach, we empower businesses of all sizes across various industries to build stronger relationships with their customers and achieve lasting success.

                      </p>
                      <p className='p-size-22px text-light'>Our offerings include tailored CRM platforms designed to meet the unique needs of businesses, centralized customer data management, sales and marketing automation tools, robust customer service and support features, and advanced analytics and reporting capabilities. Whether you're in retail, healthcare, finance, or any other sector, our adaptable CRM solutions enable you to gain valuable insights, deliver personalized experiences, streamline processes, and make informed decisions to enhance customer satisfaction and drive revenue.

                      </p>
                      <p className='p-size-22px text-light'>Choosing CRM for All Services means opting for scalability, flexibility, ease of use, and a commitment to customer success. Our CRM solutions are scalable to accommodate businesses of all sizes, offering tools and expertise to support your growth, from startups to enterprises. We understand that every business is unique, which is why we offer flexible and customizable solutions tailored to your specific needs and preferences. Our intuitive interfaces and user-friendly tools ensure easy adoption and effective use of our CRM solutions with minimal training required.

                      </p>




                    </div>
                  </div>
                  <div className='col-lg-5  d-none d-sm-block d-md-none d-lg-block'>
                    <div>
                      <p className='color mb-4 fw-bold fs-3px'>CRM For All Services</p>
                      <div className='text-center'>
                        <img className='web-product-img-set  rounded-4' src='assets/images/product/CRM for All Services.jpg' />
                      </div>
                    </div>
                  </div>

                </div>


              </Slider>
              <div className='col-lg-12 mb-4'>
                <div className='mr-2 web-line fs-1'>
                  <p className='mb-0' style={{ color: "#939393", fontSize: "18px" }}>Sankhyank Tech Solutions</p>
                </div>
              </div>

            </div>

            <div id="mousecursor" >
              <div className="mousecursor--inner" >
                <p className='fs-5' style={{ lineHeight: '93px', top: "10px", margin: 0, color: "white" }}><FontAwesomeIcon icon={faLessThan} /> &nbsp; &nbsp;<FontAwesomeIcon icon={faGreaterThan} /></p>

              </div>
            </div>
          </div>

        </div>


      </section>

      <section className="service-sec4   ">
        <div className="container padding-set ">
          <div className="">
            <div class="get-in-touch-circle">  <img className="text-center get-in-touch-image mt-3 mx-2  " src="assets/images/service/logo1.png" /></div>
            <p className="fw-bold fs-48 mt-3 text-center text-dark">Get your project off to a flying start</p>
            <p className=" mt-3 text-center  mb-4 pl-4 text-dark">Blockchain implementation is a monumental investment in your business
              longevity, as DLT now turns into one of the most future-proof tech platforms</p>
            <div className="text-center">
              {/*
      <button className="btn btn-primary  get-in-touch-btn px-4  py-2 fs-4 btn-lg mt-3 mb-5">Get in touch</button>
    */}
              <a href="#" type='button' class='butn butn__new'><Link to="/contact" style={{ textDecoration: "none", color: "inherit" }}><span>Get in touch</span></Link></a>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default Product