import React, { useState, useEffect, useRef } from 'react'
import "./About.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from "@fortawesome/react-fontawesome"
import { faEnvelope, faL } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';
import StaticStarRating from '../StaticStarRating';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { fa ,faBullseye } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import YouTube from 'react-youtube';









const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [totalStars, setTotalStars] = useState(5);
  // const [iframeSrc, setIframeSrc] = useState('https://www.youtube.com/embed/SaZymZuv-WQ');
  // const playerRef = useRef(null);

  // const [isPlaying, setIsPlaying] = useState(true);
  // const videoRef = useRef(null);

  // const toggleVideo = () => {
  //   const iframe = videoRef.current;
  //   const player = iframe.contentWindow;

  //   if (isPlaying) {
  //     player.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
  //   } else {
  //     player.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
  //   }

  //   setIsPlaying(!isPlaying);
  // };

  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const onReady = (event) => {
    setPlayer(event.target);
  };

  const toggleVideo = () => {
    if (player) {
      if (isPlaying) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
      setIsPlaying(!isPlaying);
    }
  };



  // const [mouse, setMouse] = useState({ x: -100, y: -100 });
  // const [pos, setPos] = useState({ x: 0, y: 0 });

  const [counts, setCounts] = useState({
    clients: 0,
    projects: 0,
    hoursOfSupport: 0,
    smartWorkers: 0
  });

  const targets = {
    clients: 232,
    projects: 521,
    hoursOfSupport: 1463,
    smartWorkers: 15
  };

  const sectionRef = useRef(null);

  // const sliderRef = useRef(null);

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    slickArrow: false,
    arrows: false,
    // autoplaySpeed: 1000,
  };
  const settings1 = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    slickArrow: false,
    arrows: false,
    // autoplaySpeed: 1000,
  };


  // const togglePause = () => {
  //   setIsPaused(!isPaused);
  // };

  // useEffect(() => {
  //   if (videoElement) {
     

  //     if (isPaused) {
  //       videoElement.target.pauseVideo();
  //     } else {
  //       videoElement.target.playVideo();
  //     }
  //   }
  // }, [isPaused, videoElement]);


  useEffect(() => {
    const sectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          sectionObserver.unobserve(entry.target);
        }
      });
    });

    sectionObserver.observe(sectionRef.current);

    return () => {
      sectionObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setCounts(prevCounts => {
          const newCounts = {};
          for (const key in prevCounts) {
            if (prevCounts[key] < targets[key]) {
              newCounts[key] = prevCounts[key] + 1;
            } else {
              newCounts[key] = targets[key];
            }
          }
          return newCounts;
        });
      }, 1);

      return () => clearInterval(interval);
    }
  }, [isVisible, targets]);

  useEffect(() => {

    const counters = document.querySelectorAll(".counter");

    counters.forEach((counter) => {
      counter.innerText = "0";
      const updateCounter = () => {
        const target = +counter.getAttribute("data-target");
        const count = +counter.innerText;
        const increment = target / 100;
        if (count < target) {
          counter.innerText = `${Math.ceil(count + increment)}`;
          setTimeout(updateCounter, 1);
        } else counter.innerText = target;
      };
      updateCounter();
    });
  }, [])

  // cursor code start


  // useEffect(() => {
  //   const updateCoordinates = (e) => {
  //     setMouse({ x: e.clientX, y: e.clientY });
  //   };

  //   window.addEventListener('mousemove', updateCoordinates);

  //   return () => {
  //     window.removeEventListener('mousemove', updateCoordinates);
  //   };
  // }, []);

  // const getAngle = (diffX, diffY) => {
  //   return Math.atan2(diffY, diffX) * 180 / Math.PI;
  // };

  // const getSqueeze = (diffX, diffY) => {
  //   const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
  //   const maxSqueeze = 0.15;
  //   const accelerator = 1500;
  //   return Math.min(distance / accelerator, maxSqueeze);
  // };

  // const updateCursor = () => {
  //   const diffX = Math.round(mouse.x - pos.x);
  //   const diffY = Math.round(mouse.y - pos.y);

  //   setPos({
  //     x: pos.x + diffX * 0.1,
  //     y: pos.y + diffY * 0.1
  //   });
  // };

  // useEffect(() => {
  //   const loop = () => {
  //     updateCursor();
  //     requestAnimationFrame(loop);
  //   };

  //   loop();

  //   return () => {
  //     cancelAnimationFrame(loop);
  //   };
  // }, [mouse, pos]);

  // const cursorModifiers = document.querySelectorAll('[cursor-class]');

  // const handleMouseEnter = (e) => {
  //   const className = e.target.getAttribute('cursor-class');
  //   e.target.classList.add(className);
  // };

  // const handleMouseLeave = (e) => {
  //   const className = e.target.getAttribute('cursor-class');
  //   e.target.classList.remove(className);
  // };

  // cursor code end


  // useEffect(() => {
  //   const $slider = $(sliderRef.current);

  //   $slider.on('init', () => {
  //     mouseWheel($slider);
  //   });

  //   $slider.slick({
  //     dots: true,
  //     horizontal: true,
  //     infinite: true,
  //   });

  //   function mouseWheel($slider) {
  //     $(window).on('wheel', { $slider: $slider }, mouseWheelHandler);
  //   }

  //   function mouseWheelHandler(event) {
  //     event.preventDefault();
  //     const $slider = event.data.$slider;
  //     const delta = event.originalEvent.deltaY;
  //     if (delta > 0) {
  //       $slider.slick('slickPrev');
  //     } else {
  //       $slider.slick('slickNext');
  //     }
  //   }

  //   return () => {
  //     $(window).off('wheel', mouseWheelHandler);
  //     $slider.slick('unslick');
  //   };
  // }, []);



  useEffect(() => {
    function handleWheel(event) {
      event.preventDefault();
      const delta = event.deltaY;
      if (delta > 0) {
        sliderRef.current && sliderRef.current.slickNext();
      } else {
        sliderRef.current && sliderRef.current.slickPrev();
      }
    }

    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentSlide);
    }
  }, [currentSlide]);

 

  
 



  // useEffect(() => {
  //   const cursor = document.querySelector('#mousecursor');
  //   const section = document.querySelector('#sectionToTrackMouseMovement');
  //   const mouse = { x: 300, y: 300 };
  //   const pos = { x: 0, y: 0 };
  //   const speed = 0.1; // between 0 and 1
  
  //   const updatePosition = () => {
  //     pos.x += (mouse.x - pos.x) * speed;
  //     pos.y += (mouse.y - pos.y) * speed;
  //     cursor.style.left = pos.x + 'px';
  //     cursor.style.top = pos.y + 'px';
  //   };
  
  //   const isInsideSection = (clientX, clientY) => {
  //     const sectionRect = section.getBoundingClientRect();
  //     return (
  //       clientX >= sectionRect.left &&
  //       clientX <= sectionRect.right &&
  //       clientY >= sectionRect.top &&
  //       clientY <= sectionRect.bottom
  //     );
  //   };
  
  //   // const updateCoordinates = (clientX, clientY) => {
  //   //   if (isInsideSection(clientX, clientY)) {
  //   //     cursor.style.display = 'block'; // Show cursor when inside section
  //   //     const sectionRect = section.getBoundingClientRect();
  //   //     mouse.x = clientX - sectionRect.left;
  //   //     mouse.y = clientY - sectionRect.top;
  //   //   } else {
  //   //     cursor.style.display = 'none'; // Hide cursor when outside section
  //   //   }
  //   // };

  //   const updateCoordinates = (clientX, clientY) => {
  //     const sectionRect = section.getBoundingClientRect();
  //     if (isInsideSection(clientX, clientY)) {
  //       mouse.x = clientX - sectionRect.left;
  //       mouse.y = clientY - sectionRect.top;
  //     }
  //   };
  
  
  //   const handleMouseMove = (e) => {
  //     updateCoordinates(e.clientX, e.clientY);
  //   };
  
  //   const handleTouchMove = (e) => {
  //     const touch = e.touches[0];
  //     updateCoordinates(touch.clientX, touch.clientY);
  //   };
  
  //   section.addEventListener('mousemove', handleMouseMove);
  //   section.addEventListener('touchmove', handleTouchMove);
  
  //   function loop() {
  //     updatePosition();
  //     requestAnimationFrame(loop);
  //   }
  
  //   requestAnimationFrame(loop);
  
  //   return () => {
  //     section.removeEventListener('mousemove', handleMouseMove);
  //     section.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, []);

  useEffect(() => {
    const cursor = document.querySelector('#mousecursor');
    const section = document.querySelector('#sectionToTrackMouseMovement');
    const mouse = { x: 300, y: 300 };
    const pos = { x: 0, y: 0 };
    const speed = 0.1; // between 0 and 1
  
    const updatePosition = () => {
      pos.x += (mouse.x - pos.x) * speed;
      pos.y += (mouse.y - pos.y) * speed;
      cursor.style.left = pos.x + 'px';
      cursor.style.top = pos.y + 'px';
    };
  
    const isInsideSection = (clientX, clientY) => {
      const sectionRect = section.getBoundingClientRect();
      return (
        clientX >= sectionRect.left &&
        clientX <= sectionRect.right &&
        clientY >= sectionRect.top &&
        clientY <= sectionRect.bottom
      );
    };
  
    const handleMouseMove = (e) => {
      const clientX = e.clientX;
      const clientY = e.clientY;
      if (isInsideSection(clientX, clientY)) {
        cursor.style.display = 'block'; // Show cursor when inside section
        const sectionRect = section.getBoundingClientRect();
        mouse.x = clientX - sectionRect.left;
        mouse.y = clientY - sectionRect.top;
      } else {
        cursor.style.display = 'none'; // Hide cursor when outside section
      }
    };
  
    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const clientX = touch.clientX;
      const clientY = touch.clientY;
      if (isInsideSection(clientX, clientY)) {
        cursor.style.display = 'block'; // Show cursor when inside section
        const sectionRect = section.getBoundingClientRect();
        mouse.x = clientX - sectionRect.left;
        mouse.y = clientY - sectionRect.top;
      } else {
        cursor.style.display = 'none'; // Hide cursor when outside section
      }
    };
  
    section.addEventListener('mousemove', handleMouseMove);
    section.addEventListener('touchmove', handleTouchMove);
  
    function loop() {
      updatePosition();
      requestAnimationFrame(loop);
    }
  
    requestAnimationFrame(loop);
  
    return () => {
      section.removeEventListener('mousemove', handleMouseMove);
      section.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);
  

  
  




  return (
    <>

    {/*
    
    <section>
    <div className="container-fluid" >
    <div className='about-head-set' >
    <h1 className='setAbout-head fs-3rem text-center' >
    Engineering is our thing
    </h1>
    </div>
    
    <img className="about-img-set" src="assets/images/about/About2.jpg"  />
    </div>
    </section>
  */}
  
  {/**
  <section>
        <div class="container-fluid">
            <div class='about-head-set'>
                <h1 class='setAbout-head fs-3rem text-center'>
                    Engineering is our thing
                </h1>
            </div>
            <div className='d-none d-lg-block d-md-block d-sm-block'>
            <img class="about-img-set" src="assets/images/about/About-img.jpg" alt="About Image" />
            </div>
            <div className='d-block d-lg-none d-md-none d-sm-none'>
            <img class="about-img-set" src="assets/images/about/About-mobile.jpg" alt="About Image" />
            </div>
            </div>
            </section>
            
            
            <section className=''>
            <div className='container-fluid'>
    <div className='img-container'>
    <div className='about-content'>
    <h1 className='fw-bold fs-3rem '> Engineering is our thing
    </h1>

     
    
    
        <div className='myImg d-none d-lg-block d-md-block d-sm-block'>
        <img class="about-img-set" src="assets/images/about/About (4).jpg" alt="About Image" />
        </div>
        <div className='myImg d-block d-lg-none d-md-none d-sm-none'>
        <img class="about-img-set" src="assets/images/about/About-mobile.jpg" alt="About Image" />
        </div>
        
        
        </div>
        </div>
  </div>
  </section>
  
*/}
{/*
<section className=''>
<div className='container-fluid'>
    <div className='about-content'>
      <p className='fw-bold fs-3rem '>Engineering is our thing
      </p>
    </div>

    <div className='myImg d-none d-lg-block d-md-block d-sm-block'>
    <img class="about-img-set" src="assets/images/banner/About.jpg" alt="About Image" />
    </div>
    <div className='myImg d-block d-lg-none d-md-none d-sm-none'>
    <img class="about-img-set" src="assets/images/about/About-mobile.jpg" alt="About Image" />
    </div>
    
    </div>
    </section>
  */}
<div className='about'>
<section className=''>
<div className='container-fluid p-0'>
    <div className='about-content'>
      <p className='fw-bold text-light fs-33rem '>Engineering is what we do best
      </p>
    </div>

    <div className='myImg d-none d-lg-block d-md-block d-sm-block'>
    <img class="about-img-set" src="assets/images/banner/About.jpg" alt="About Image" />
    </div>
    <div className='myImg d-block d-lg-none d-md-none d-sm-none'>
    <img class="about-img-set" src="assets/images/about/About-mobile.jpg" alt="About Image" />
    </div>
    {/*
  */}

  </div>
</section>




      <section className='about-sec2-set  '>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 px-lg-4  col-md-10'>
              <h2 className='fs-3rem fw-bold  mb-4 text-center'>The Transformative potential of diversity, equity, and inclusion is unparalleled in fostering meaningful social change.</h2>
              <p className='text-light mb-0 text-center'>Collaborating closely, we empower clients to envision, construct, and nurture digital products and experiences that redefine their landscape. Our mission is to jointly shape the future alongside visionary partners who, like us, embrace the boundless potential of technology. We continuously challenge ourselves to elevate ideas with heightened quality and accelerated innovation.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='about-sec3-set'>
        <div className='container-fluid'>
          <div className='row px-lg-0 px-md-5  px-3 justify-content-end'>
            <div className='col-lg-5'>

              <div className='contain'>
                <h1 className='mb-4 fs-bold'>Small Business? Big Business?</h1>
                <h1 className='mb-4 fs-bold ' style={{ color: "#FF851B" }}>We got you covered.</h1>
                <p className='fs-4 text-dark'>At STS, We develop website and software from scratch by implementing latest
                  tools and technologies. Our team of developer is fully trained to perform any
                  type of technical or logical task in minimum time by implementing latest and
                  advanced technologies</p>

                <div className='px-lg-3 p-set-mobile pt-0 m-lg-5 m-md-5 pb-0 mb-0 '>
                  <p className='set-number mt-3'>01</p>
                  <h2 className='mb-3'>Why to pick us?</h2>
                  <p className='mb-5 text-dark'>Our team of Web design experts and developers is determined to discover
                    and provide full-service Web development solutions to improve your brand
                    recognition</p>

                  <p className='set-number'>02</p>
                  <h2 className='mb-3'>We work with You</h2>
                  <p className='mb-5 text-dark'>We attentively and thoroughly listen to our client's needs and solve their
                    issues most efficiently to ensure that your needs and deliver our services
                    based on that. We keep you in the loop and keep you updated about every
                    recent development</p>

                  <p className='set-number'>03</p>
                  <h2 className='mb-3'>We work with You</h2>
                  <p className='mb-5 text-dark'>After designing a platform, we send the concept to the client for review
                    and feedback. We continue to look for new elements until our clients are
                    completely satisfied with our efforts. After that, the design you approve will
                    be coded and created.</p>
                </div>

              </div>
            </div>
            <div className='col-lg-6 text-end'>
              <div className='img set-image-relative ' >
              <a type='button' className='modal-dialog-centered '   data-bs-toggle="modal" data-bs-target="#exampleModal">
              <img className='bussiness-img' src='assets/images/about/about section image (1).jpg' />
                <div class="wrapper">
    <div class="video-main">
      <div class="promo-video">
        <div class="waves-block">
          <div class="waves wave-1"></div>
          <div class="waves wave-2"></div>
          <div class="waves wave-3"></div>
        </div>
      </div>
      <div className=''>
      <a type='button' class="video video-popup mfp-iframe " data-lity><FontAwesomeIcon className='' icon={faPlay} /></a></div>
      </div>
      </div>
      </a>

      
      </div>
      </div>
      
      </div>
      
      
      </div>
      </section>

      <div class="modal  model-lg fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content" style={{border:"3px solid #e67410",background:"transparent"}}>
     
      <div class="modal-body p-0 " style={{width:"auto"}} >
      <div     
      >
      <button type="button" 
      class="btn-close" 
      data-bs-dismiss="modal"  
      aria-label="Close" 
      onClick={toggleVideo}
      style={{position:'absolute',right:'0px',top:'0px',padding:'10px',background:'transparent',opacity:'1',color:'#e67410',display:"flex",alignItems:"center",justifyContent:"center"}}><FontAwesomeIcon icon={faTimes}/>
      </button>
      </div>
      {/*
      onClick={handlePause}
      
      
      
      
      <iframe
      className='height-450px'
      id="myVideo"
      width="100%"
      src="https://www.youtube.com/embed/SaZymZuv-WQ?enablejsapi=1"
      title="Why Spider AI ? Most Advanced SEO tools of 2023 | Artificial Intelligence for SEO"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      ></iframe>
      <video width="320" height="240" controls  ref={videoRef}>
      <source src="https://www.youtube.com/embed/SaZymZuv-WQ" type="video/mp4" />
      </video>
      <iframe style={{border:"3px solid #e67410", borderRadius:"22px"}}  src={iframeSrc}   ref={playerRef} className='height-450px' id="myVideo" width="100%"   title="Why Spider AI ? Most Advanced SEO tools of 2023 | Artificial Intelligence for SEO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <video  controls  ref={videoRef}>
      <source src="assets/images/about/video-about.mp4" type="video/mp4" />
      </video>
      <div  ref={videoRef}>
      </div>
      <video  style={{border:"3px solid #e67410", borderRadius:"22px"}} id="myVideo" width="100%" controls autoPlay   >
      <source  className='height-450px' src="assets/images/about/spider ai.mp4" type="video/mp4" />
      </video>
      ref={videoRef}
      <iframe style={{border:"3px solid #e67410", borderRadius:"22px"}} ref={playerRef} src="https://www.youtube.com/embed/SaZymZuv-WQ" className='height-450px' id="myVideo" width="100%"   title="Why Spider AI ? Most Advanced SEO tools of 2023 | Artificial Intelligence for SEO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    */}

    <YouTube
    style={{ width:"100%", height:"450px" ,borderRadius:"20px !important" , overflow:"hidden", top:"0% !important"}} 
    onReady={onReady}  
    videoId="SaZymZuv-WQ"
    src="https://www.youtube.com/embed/SaZymZuv-WQ"
    opts={{ width: '100%', height: '450px', playerVars: { autoplay: 0 },  }}
    containerClassName="rounded-3"
        className="rounded-3 d-none d-sm-block d-md-block d-lg-block"
      />

      <YouTube
      style={{ width:"100%", height:"220px" ,borderRadius:"20px !important" , overflow:"hidden", top:"0% !important"}} 
      onReady={onReady}  
      videoId="SaZymZuv-WQ"
      src="https://www.youtube.com/embed/SaZymZuv-WQ"
      opts={{ width: '100%', height: '220px', playerVars: { autoplay: 0 },  }}
      containerClassName="rounded-3"
          className="rounded-3 d-block d-sm-none d-md-none d-lg-none"
        />

  

      </div>
     
    </div>
  </div>
</div>




      <section className='sec2-set' style={{ backgroundColor: "#0A2D3E" }}>
        <div className='container-fluid'>
          <div className='row mb-5 justify-content-evenly align-items-center'>
            <div className='col-lg-6 mt-lg-0 mt-md-5 mb-lg-0 mb-0'>
            {/*
            <FontAwesomeIcon icon="fas fa-bullseye-arrow" />
          */}
          <p className='text-center mb-0'>
<img  className='mission-img-set' src="assets/images/about/mission.png" />          </p>
            <p className='text-center fs-41rem fw-bold'>
            MISSION 
            </p>
            </div>
            <div className='col-lg-6 mt-lg-0 mt-md-1 mb-lg-0 mb-5'>
              <p className='fs-4 text-center'>At Sankhyank Tech Solution, our mission is to empower businesses and individuals with innovative IT solutions that enhance productivity, streamline operations, and drive growth. Through cutting-edge technology and unparalleled service, we strive to be the catalyst for our clients' success, fostering long-term partnerships built on trust, integrity, and excellence.</p>
            </div>
            </div>
            <hr />
            <div className='mt-5'>
            <div className='row justify-content-evenly align-items-center'>
            <div className='col-lg-6 mt-lg-0 mt-md-5 mb-lg-0 mb-0 d-block d-sm-none d-md-block d-lg-none'>
            <p className='text-center mt-4 mb-0'>

            <img  className='vision-img-set' src="assets/images/about/vision.png" />   
            </p>            
            <p className='text-center fs-41rem fw-bold'>
            VISION 
            </p>
            </div>

            <div className='col-lg-6 mb-lg-0 mb-0 mt-lg-0 mt-md-1 mb-md-5 mt-0'>
            <p className='fs-4 text-center'>Our vision at Sankhyank Tech Solution is to revolutionize the digital landscape, becoming the foremost provider of IT products and services globally. We envision a future where technology seamlessly integrates with everyday life, simplifying complexities and unlocking endless possibilities. By pioneering advancements and embracing change, we aim to shape a world where connectivity knows no bounds, driving progress and prosperity for all.</p>
            </div>
            <div className='col-lg-6 mt-lg-0 mt-md-5 mb-lg-0 mb-5  d-none d-sm-block d-md-none d-lg-block'>
            <p className='text-center mb-0'>
            <img  className='vision-img-set' src="assets/images/about/vision.png" />   
            </p>
            <p className='text-center fs-41rem fw-bold'>
            VISION 
            </p>
            </div>
            </div>
          </div>
        </div>
      </section>




      <section  className='sec2-set'>
        <div className='container-fluid'>
          <h3 style={{fontSize:"2.2rem"}} className='text-center mb-4 fw-bold text-light'>What we have achieved so far</h3>
          <h3 className='text-center mb-5 pt-2 pb-4 d-none d-sm-block d-md-block d-lg-block'>Successfully we have achieved these numbers and continuously increasing them day by day.</h3>
          <h4 className='text-center mb-5 pt-2 pb-4 d-block d-sm-none d-md-none d-lg-none '>Successfully we have achieved these numbers and continuously increasing them day by day.</h4>

          <div className='row ' ref={sectionRef}>
            <div className='col-lg-3 col-sm-6 text-center mb-lg-3 mb-5'>
              <h1 style={{ color: "#FF851B" }}>{counts.clients}+</h1>

              {/*
                <h1 class="counter" data-target="7500"></h1>
              */}

              <h2>Clients</h2>
            </div>
            <div className='col-lg-3 col-sm-6  text-center mb-lg-3 mb-5'>
              <h1 style={{ color: "#FF851B" }}>{counts.projects}+</h1>
              <h2>Projects</h2>
            </div>
            <div className='col-lg-3 col-sm-6  text-center mb-lg-3 mb-5'>
              <h1 style={{ color: "#FF851B" }}>{counts.hoursOfSupport}+</h1>
              <h2>Hours Of Support</h2>
            </div>
            <div className='col-lg-3 col-sm-6  text-center mb-lg-3 mb-4'>
              <h1 style={{ color: "#FF851B" }}>{counts.smartWorkers}+</h1>
              <h2>Smart Workers</h2>
            </div>
          </div>
        </div>
      </section>
      {/*
      <div id="cursor">
      <div className="cursor__circle" style={{ transform: `rotate(${getAngle()}deg) scale(${1 + getSqueeze()}, ${1 - getSqueeze()})` }}></div>
      {cursorModifiers.forEach(cursorModifier => (
        <div
        key={cursorModifier}
        className="cursor-modifier"
        cursor-class={cursorModifier.getAttribute('cursor-class')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        />
        ))}
        </div>
        
      */}
      
      <section className='py-5' style={{backgroundColor:"#0E4536"}} id='sectionToTrackMouseMovement'>
  
        <div className='container-fluid' >
        {/**/}
        <div className='row justify-content-center align-items-center'>
        <div className='col-lg-10 col-md-12'>

        <h1 className='mb-0 text-center fw-bold color'>TESTIMONIALS</h1>
        {/*
          <Slider {...settings} className="mt-0">
            <div className='mt-0'>
              <i class="fa fa-quote-left" aria-hidden="true"></i>
              <div class="circle mt-5">
              <p className='fs-4rem color set-icon' ><FontAwesomeIcon icon={faQuoteRight} /></p>
              </div>
              <div className='border mb-4 mx-5 rounded-5 text-center'>
                <p className='mt-2  fs-30px '>Great company, had great experience in their SEO service. They best practice to give boost to your website on search engine.
                Appreciate their service technology, started getting organic enquiry for my business.
                
                Great experience with this company.</p>
                 
                  
                <StaticStarRating totalStars={5} rating={5} />

              </div>



            </div>
            <div>

              <div class="circle mt-5">
              <p className='fs-4rem color set-icon' ><FontAwesomeIcon icon={faQuoteRight} /></p>
              </div>
              <div className='border mx-5 rounded-5 text-center'>
                <p className='mt-2  fs-30px '>We approached Sankhyank Tech Solutions for our web development needs, and they surpassed our expectations. Their attention to detail and dedication to delivering a seamless online platform were truly commendable.
                </p>
                <StaticStarRating totalStars={5} rating={4.5} />

              </div>



            </div>
            <div>

              <div class="circle mt-5">
              <p className='fs-4rem color set-icon' ><FontAwesomeIcon icon={faQuoteRight} /></p>
              </div>
              <div className='border mx-5 rounded-5 text-center'>
                <p className='mt-2 fs-30px  '>Digital forensics is a critical aspect of our business, and Sankhyank Tech Solutions provided us with top-notch solutions. Their thorough analysis and quick response helped us mitigate potential security threats effectively. We trust them with our digital security needs.
                </p>
                <StaticStarRating totalStars={5} rating={4} />

              </div>



            </div>

            <div>

            <div class="circle mt-5">
            <p className='fs-4rem color set-icon' ><FontAwesomeIcon icon={faQuoteRight} /></p>
            </div>
            <div className='border mx-5 rounded-5 text-center'>
              <p className='mt-2 fs-30px  '>SpiderAI has revolutionized our SEO strategy, thanks to Sankhyank Tech Solutions. Their innovative product not only helped us improve our search engine rankings but also provided valuable insights for optimizing our online presence.</p>
              <StaticStarRating totalStars={5} rating={4} />

            </div>



          </div>
          </Slider>



        */}

        <div className='mt-0'>
        <i class="fa fa-quote-left" aria-hidden="true"></i>
        <div class="circle mt-5">
        <p className='fs-4rem color set-icon' ><FontAwesomeIcon icon={faQuoteRight} /></p>
        </div>
        <div className='border mb-4 mx-3 rounded-5 text-center'>
        <Slider {...settings1} className="mt-0 mb-0">

        <div className='   rounded-5 text-center'>
            <p className='mt-2  fs-30px '>Great company, had great experience in their SEO service. They best practice to give boost to your website on search engine.
            Appreciate their service technology, started getting organic enquiry for my business.
            
            Great experience with this company.</p>
             
              
            <StaticStarRating totalStars={5} rating={5} />
            </div>

            <div className='  rounded-5 text-center'>
            <p className='mt-2  fs-30px '>We approached Sankhyank Tech Solutions for our web development needs, and they surpassed our expectations. Their attention to detail and dedication to delivering a seamless online platform were truly commendable.
            </p>
            <StaticStarRating totalStars={5} rating={4.5} />

          </div>

          <div className='  rounded-5 text-center'>
          <p className='mt-2 fs-30px  '>Digital forensics is a critical aspect of our business, and Sankhyank Tech Solutions provided us with top-notch solutions. Their thorough analysis and quick response helped us mitigate potential security threats effectively. We trust them with our digital security needs.
          </p>
          <StaticStarRating totalStars={5} rating={4} />

        </div>

        <div className='  rounded-5 text-center'>
        <p className='mt-2 fs-30px  '>SpiderAI has revolutionized our SEO strategy, thanks to Sankhyank Tech Solutions. Their innovative product not only helped us improve our search engine rankings but also provided valuable insights for optimizing our online presence.</p>
        <StaticStarRating totalStars={5} rating={4} />

      </div>

            </Slider>
          </div>



        </div>
   
  

        <div>

  
   



      </div>





        </div>
        {/*
        <div id="mousecursor">
        <div class="mousecursor--inner"> <p>&#60; &#62;</p></div>
        <p style={{ lineHeight: '50px', margin: 0 , color:"white" }}>&#60; &#62;</p>
        </div>
      */}
        <div id="mousecursor" >
        <div className="mousecursor--inner" >
                <p className='fs-5' style={{ lineHeight: '93px', top:"10px", margin: 0 , color:"white" }}><FontAwesomeIcon icon={faLessThan} /> &nbsp; &nbsp;  <FontAwesomeIcon icon={faGreaterThan} /></p>

        </div>
        </div>
        </div>

        </div>
      
      
      </section>
      </div>
    </>
  )
}

export default About