import { useState, useEffect } from "react"
import React from 'react'
import "../Service/Service.css"
import HashLoader from "react-spinners/HashLoader";
import { Link } from "react-router-dom";



const Service = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  }, [])

  return (
    <>

      {/*

        {
          loading?
          <div style={{textAlign:"-webkit-center"}}>
          <HashLoader
          
          color={'#3ce0bf'}
          loading={loading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
          </div>
          :
                */}


      <section className=''>
        <div className='container-fluid p-0'>
            <div className='service-content'>
              <p className='fw-bold text-light fs-31rem '>Empowering Your Digital Future
              </p>
              <p className="fw-bold text-light fs-31rem ">Innovative Solutions, Seamless Experience</p>
            </div>

            <div className='myImg d-none d-lg-block d-md-block d-sm-block'>
            <img class="service-img-set" src="assets/images/service/service.jpg" alt="About Image" />
            </div>
            <div className='myImg d-block d-lg-none d-md-none d-sm-none'>
            <img class="service-img-set" src="assets/images/service/service.jpg" alt="About Image" />
            </div>
            {/*
          */}

          </div>
      </section>

      <section className="service-sec2 pt-5">
        <div className="container  px-md-5 px-4">
          <div className="py-5 p-sm-block p-md-block p-lg-block">
            <h1 className=" fw-bold">Crafting Tomorrow's Digital Landscapes</h1>
            <p className="fs-4 text-light">Crafting dynamic online experiences tailored to your brand's vision,
              with precision in design and functionality, to captivate and engage
              your audience.</p>
          </div>
        </div>
      </section>


      <section className="service-sec3 pb-2" id="web-dev-section">
        <div className="container-fluid p-lg-5 p-md-5 py-4 px-4">
          <div className="row p-lg-5 px-md-3 justify-content-around " >
            <div className="col-lg-4 col-md-6 ">

              <div>

                <ul class="nav nav-pills nav-pills-digital nav-head  d-none d-sm-block d-md-none d-lg-block" id="pills-tab" role="tablist">
                  <li className="nav-item bdr-bottom active" role="presentation">
                    <div class="nav-link   active nav-link-top" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                      <h5 className="clr-org mt-3 fw-bold  ml-10" >Web Development</h5>
                      <p className=" fs-12px ml-10" style={{ color: "#F2F4F6" }}>Building and designing websites to establish a digital presence for businesses or individuals.
                      </p>
                    </div>
                  </li>
                  <li className="nav-item bdr-bottom" role="presentation">
                    <div className="nav-link nav-link-middle " id="pills-web2-tab" data-bs-toggle="pill" data-bs-target="#pills-web2" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                      <h5 className="clr-org mt-3 fw-bold  ml-10" id="e-comerece">E-Commerce Development</h5>
                      <p className=" fs-12px ml-10" style={{ color: "#F2F4F6" }}> Creating online stores and platforms to sell products or services over the internet, facilitating online transactions and commerce.</p>

                    </div>
                  </li>
                  <li className="nav-item bdr-bottom" role="presentation">
                    <div className="nav-link nav-link-middle " id="pills-web3-tab" data-bs-toggle="pill" data-bs-target="#pills-web3" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                      <h5 className="clr-org mt-3 ml-10 fw-bold">Hybrid Mobile App Developer</h5>
                      <p className="fs-12px ml-10" style={{ color: "#F2F4F6" }}>Developing cross-platform mobile applications leveraging a unified codebase for iOS and Android, blending native and web elements.


                      </p>

                    </div>
                  </li>
                  <li className="nav-item bdr-bottom" role="presentation">
                    <div className="nav-link  nav-link-middle" id="pills-web4-tab" data-bs-toggle="pill" data-bs-target="#pills-web4" type="button" role="tab" aria-controls="pills-backchain" aria-selected="false">
                      <h5 className="clr-org ml-10  mt-3 fw-bold">Webflow Development</h5>
                      <p className="fs-12px ml-10" style={{ color: "#F2F4F6" }}>Designing and developing websites visually without code, using the Webflow platform to create responsive and interactive web experiences.</p>

                    </div>
                  </li>
                  <li className="nav-item " role="presentation">
                  <div className="nav-link  nav-link-last" id="pills-web5-tab" data-bs-toggle="pill" data-bs-target="#pills-web5" type="button" role="tab" aria-controls="pills-backchain" aria-selected="false">
                    <h5 className="clr-org ml-10  mt-3 fw-bold">Digital Forensics</h5>
                    <p className="fs-12px ml-10" style={{ color: "#F2F4F6" }}>Digital forensics uncovers evidence from digital devices using methods like data recovery, aiming to preserve and interpret it for legal use.</p>

                  </div>
                </li>



                </ul>
              </div>

            </div>
            <div className="col-lg-7  col-md-6" >

              <div className="tab-content d-none d-sm-block d-md-none d-lg-block" id="pills-tabContent">
                <div className="tab-pane  show active  " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" >

                  <div className=' ' id="web-dev" >
                    <div className=" ">
                      <h1 className="clr-org mb-4 fw-bold">Web Development</h1>
                      <p className=" fs-14px  text-light ">Web development is a multifaceted process that begins with the planning stage, where the purpose, goals, and target audience of the website are identified. This phase involves determining the required content and features, as well as outlining the site's structure and navigation to ensure a cohesive user experience. Concurrently, the design phase focuses on establishing the visual appearance and layout of the website. Design elements such as color schemes, typography, imagery, and branding are established to create a visually appealing and consistent design. Wireframes and mockups may also be created to visualize the site's layout and structure before progressing to the development phase.

                      </p>
                      <p className=" fs-14px  text-light ">In the development phase, the actual coding and programming of the website take place. Front-end development involves writing HTML, CSS, and JavaScript code to create the user interface and implement the design elements, while back-end development focuses on building server-side functionality, including database integration, user authentication, and content management systems (CMS) if needed. These stages ensure seamless functionality and performance, laying the groundwork for the website's content.


                      </p>
                      <p className=" fs-14px  text-light ">Content creation is an integral component that runs parallel to the design and development phases. During this stage, text, images, videos, and other multimedia elements are created or gathered and optimized for the web. Content is organized and structured according to the site's design and navigation, enhancing the overall user experience and engagement.

                      </p>
                      <p  className=" fs-14px  text-light ">The final stage encompasses testing, launch, and maintenance. The website undergoes rigorous testing to ensure proper functionality across various devices, browsers, and screen sizes. Upon successful testing and final approval, the website is launched and made live to the public. This involves transferring the website files to a web server, configuring domain settings, and possibly submitting the site to search engines. Ongoing maintenance and updates are crucial to ensure the site remains secure, functional, and up-to-date. Regular monitoring, bug fixes, feature additions, and content updates are performed to meet evolving user needs and technological advancements. Effective communication and collaboration among stakeholders throughout the entire website development process are essential to ensure the final product meets the expectations and objectives of the target audience.



                      </p>


                      <img className="web-img-set text-center mt-4" src="assets/images/service/Web development.png" />

                    </div>

                  </div>
                </div>
                <div className="tab-pane   " id="pills-web2" role="tabpanel" aria-labelledby="pills-web2-tab"  >

                  <div className=' ' id="e-commerce">
                    <div className="">
                      <h1 className="clr-org mb-4 fw-bold">E-Commerce Development</h1>
                      <p className=" fs-14px  text-light">E-commerce development involves creating online platforms where businesses can sell products or services to customers over the internet. This process includes designing, developing, and implementing functionalities that facilitate online transactions, manage inventory, process payments securely, and provide a seamless shopping experience for users.
                      </p>
                          <p className=" fs-14px  text-light">Key aspects of e-commerce development include:
                          </p>
                          <p className=" fs-14px  text-light">Website Design: Crafting visually appealing and user-friendly interfaces that engage customers and reflect the brand identity. Design elements such as layout, navigation, product displays, and checkout process are optimized to enhance the user experience.
                          </p>
                          <p className=" fs-14px  text-light">E-commerce Functionality: Implementing essential features such as product catalogs, search functionality, shopping carts, wishlists, and secure checkout processes. These functionalities are designed to streamline the buying process and provide customers with a convenient shopping experience.
                          </p>
                          <p className=" fs-14px  text-light">Payment Gateway Integration: Integrating payment gateways to facilitate secure online transactions. This involves implementing encryption protocols and compliance with industry standards to ensure the security of sensitive customer information during payment processing.
                          </p>
                          <p className=" fs-14px  text-light">Inventory Management: Developing systems to manage product inventory, track stock levels, and update product availability in real-time. This helps businesses to efficiently manage their inventory and prevent overselling or stockouts.
                          </p>
                          <p className=" fs-14px  text-light">Order Management: Building order management systems to track orders, process payments, generate invoices, and manage shipping and delivery logistics. This ensures smooth order processing and fulfillment for both businesses and customers.
                          </p>
                          <p className=" fs-14px  text-light">Security: Implementing robust security measures to protect against cyber threats, data breaches, and fraudulent activities. This includes SSL encryption, PCI compliance, secure authentication, and regular security audits to safeguard customer information and transaction data.
                          </p>
                          <p className=" fs-14px  text-light">Mobile Optimization: Ensuring that the e-commerce website is optimized for mobile devices to provide a seamless shopping experience across different screen sizes and devices. This involves responsive design, mobile-friendly navigation, and optimized performance for mobile users.
                          </p>
                          <p className=" fs-14px  text-light  mb-4">Overall, e-commerce development aims to create online platforms that not only facilitate transactions but also build trust with customers, drive sales, and support business growth in the digital marketplace.</p>
                         <img className="web-img-set mt-4" src="assets/images/service/E-Commerance Development.png" />

                    </div>

                  </div>
                </div>
                <div className="tab-pane   " id="pills-web3" role="tabpanel" aria-labelledby="pills-web3-tab" >

                  <div className=' ' id="mobile">
                    <div className="">
                      <h1 className="clr-org mb-4 fw-bold">Hybrid Mobile App Developer</h1>
                      <p className=" mb-4 fs-14px text-light ">A hybrid mobile app developer is a professional who specializes in creating mobile applications that can run on multiple platforms, such as iOS, Android, and sometimes even web browsers, using a single codebase. Unlike native app development, where separate codebases are required for each platform, hybrid app development leverages web technologies like HTML, CSS, and JavaScript to build cross-platform applications.
                      </p>
                      <p className="fs-14px  text-light">Hybrid app developers typically use frameworks such as Apache Cordova, Ionic, React Native, or Flutter to develop apps that have a native-like user experience across different platforms. These frameworks provide tools and APIs to access native device features, such as camera, GPS, and push notifications, while still allowing developers to write most of the code in web technologies.
                      </p>
                      <p className="fs-14px  text-light">The advantage of hybrid app development is that it enables faster development, easier maintenance, and cost-effectiveness compared to building separate native apps for each platform. However, hybrid apps may not always achieve the same level of performance or user experience as native apps, especially for complex or resource-intensive applications.
                      </p>
                      <p className=" fs-14px mb-4  text-light">Overall, hybrid mobile app developers play a crucial role in bridging the gap between web and native app development, enabling businesses to reach a wider audience with their mobile applications while optimizing development time and resources.</p>
                     <img className="web-img-set mt-4" src="assets/images/service/Hybrid Mobile App Developer.png" />

                    </div>

                  </div>
                </div>

                <div className="tab-pane   " id="pills-web4" role="tabpanel" aria-labelledby="pills-web4-tab" >

                  <div className=' '  id="webflow">
                    <div className="">
                      <h1 className="clr-org mb-4 fw-bold">Webflow Development</h1>
                      <p className="mb-4 fs-14px  text-light">Webflow is a powerful visual web design tool that enables users to create responsive websites without writing code. It combines the flexibility of traditional web design with the simplicity of a drag-and-drop interface, allowing designers to build custom websites quickly and efficiently.
                      </p>
                      <p className="fs-14px  text-light">With Webflow, designers can create complex layouts, animations, and interactions visually, without the need for coding skills. It offers a wide range of pre-designed elements and templates, as well as the ability to customize every aspect of a website's design and functionality.
                      </p>
                      <p className="fs-14px  text-light">One of the key features of Webflow is its ability to generate clean, semantic HTML, CSS, and JavaScript code behind the scenes. This means that the websites built with Webflow are not only visually stunning but also optimized for performance and accessibility.
                      </p>
                      <p className="fs-14px  text-light">Webflow also provides hosting and content management capabilities, allowing users to publish and manage their websites directly from the platform. This makes it an all-in-one solution for designers and businesses looking to create and maintain professional websites without the need for technical expertise.
                      </p>
                      <p className="fs-14px  text-light mb-4">In summary, Webflow is a versatile tool that empowers designers to create beautiful, responsive websites with ease, while also providing the flexibility and control needed to bring their creative vision to life.</p>

                      <img className="web-img-set mt-4" src="assets/images/service/Webflow Development.png" />
                    </div>

                  </div>
                </div>

                <div className="tab-pane   " id="pills-web5" role="tabpanel" aria-labelledby="pills-web5-tab" >

                <div className=' ' id="forensic">
                  <div className="">
                    <h1 className="clr-org mb-4 fw-bold">Digital Forensics   </h1>
                    <p className="mb-4 fs-14px  text-light">Digital forensics, also known as computer forensics, is a branch of forensic science that deals with the recovery, preservation, analysis, and presentation of digital evidence. It involves investigating digital devices and data to determine what happened, how it happened, and who was involved in various digital incidents, such as cybercrimes, data breaches, hacking, intellectual property theft, and fraud.</p>
                    <p className="fs-14px  text-light">Key aspects of digital forensics include:

                    Evidence Collection: Gathering digital evidence from various sources such as computers, mobile devices, servers, cloud services, and network traffic. This involves creating forensic images of storage devices to preserve the original data and prevent any alterations.</p>
                    <p className="fs-14px  text-light">Evidence Preservation: Ensuring the integrity and authenticity of digital evidence through proper handling, storage, and documentation to maintain its admissibility in legal proceedings.</p>
                    <p className="fs-14px  text-light">Data Recovery: Employing specialized tools and techniques to recover deleted, hidden, or encrypted data from storage devices.</p>
                    <p className="fs-14px  text-light">Analysis: Examining digital evidence to identify relevant information, patterns, and relationships. This may involve examining file metadata, internet history, communication logs, and system logs to reconstruct events and timelines.</p>
                    <p className="fs-14px  text-light">Investigative Techniques: Utilizing various investigative methods such as keyword searching, data carving, timeline analysis, and malware analysis to uncover evidence and gain insights into digital incidents.</p>
                    <p className="fs-14px  text-light">Legal Considerations: Adhering to legal and ethical guidelines throughout the investigation process, including obtaining proper authorization, maintaining chain of custody, and ensuring the privacy rights of individuals.</p>
                    <p className="fs-14px  text-light">Reporting: Documenting findings and conclusions in a clear and comprehensive manner to support legal proceedings, regulatory compliance, or internal investigations.</p>
                    <p className="fs-14px  text-light mb-4">Digital forensics professionals typically have a background in computer science, information technology, or law enforcement. They may work in various sectors, including law enforcement agencies, government organizations, corporate security teams, consulting firms, and legal firms.</p>


                    <img className="web-img-set mt-4" src="assets/images/service/digital forensics.png" />
                  </div>

                </div>
              </div>

              </div>
            </div>
          </div>


          <div className="row d-block d-lg-none d-md-block d-sm-none ">


          <div className="col-sm-12">


                <div className="mb-5 pb-5" id="web-dev-section">
                  <h1 className="clr-org fw-bold mb-4">Web Development</h1>
                  <p className=" text-light ">Web development is a multifaceted process that begins with the planning stage, where the purpose, goals, and target audience of the website are identified. This phase involves determining the required content and features, as well as outlining the site's structure and navigation to ensure a cohesive user experience. Concurrently, the design phase focuses on establishing the visual appearance and layout of the website. Design elements such as color schemes, typography, imagery, and branding are established to create a visually appealing and consistent design. Wireframes and mockups may also be created to visualize the site's layout and structure before progressing to the development phase.

                  </p>
                  <p className=" text-light ">In the development phase, the actual coding and programming of the website take place. Front-end development involves writing HTML, CSS, and JavaScript code to create the user interface and implement the design elements, while back-end development focuses on building server-side functionality, including database integration, user authentication, and content management systems (CMS) if needed. These stages ensure seamless functionality and performance, laying the groundwork for the website's content.


                  </p>
                  <p className="text-light ">Content creation is an integral component that runs parallel to the design and development phases. During this stage, text, images, videos, and other multimedia elements are created or gathered and optimized for the web. Content is organized and structured according to the site's design and navigation, enhancing the overall user experience and engagement.

                  </p>
                  <p  className="text-light mb-4">The final stage encompasses testing, launch, and maintenance. The website undergoes rigorous testing to ensure proper functionality across various devices, browsers, and screen sizes. Upon successful testing and final approval, the website is launched and made live to the public. This involves transferring the website files to a web server, configuring domain settings, and possibly submitting the site to search engines. Ongoing maintenance and updates are crucial to ensure the site remains secure, functional, and up-to-date. Regular monitoring, bug fixes, feature additions, and content updates are performed to meet evolving user needs and technological advancements. Effective communication and collaboration among stakeholders throughout the entire website development process are essential to ensure the final product meets the expectations and objectives of the target audience.






                  </p>                     

                  <img className="web-img-set text-center  mb-4" src="assets/images/service/Web development.png" />
                  </div>


                <div className="mb-5 pb-5"  id="e-commerce">
                  <h1 className="clr-org fw-bold mb-4" >E-Commerce Development</h1>
                  <p className=" mb-4 text-light">E-commerce development involves creating online platforms where businesses can sell products or services to customers over the internet. This process includes designing, developing, and implementing functionalities that facilitate online transactions, manage inventory, process payments securely, and provide a seamless shopping experience for users.
                  </p>
                      <p className="text-light">Key aspects of e-commerce development include:
                      </p>
                      <p  className="text-light">Website Design: Crafting visually appealing and user-friendly interfaces that engage customers and reflect the brand identity. Design elements such as layout, navigation, product displays, and checkout process are optimized to enhance the user experience.
                      </p>
                      <p  className="text-light">E-commerce Functionality: Implementing essential features such as product catalogs, search functionality, shopping carts, wishlists, and secure checkout processes. These functionalities are designed to streamline the buying process and provide customers with a convenient shopping experience.
                      </p>
                      <p  className="text-light">Payment Gateway Integration: Integrating payment gateways to facilitate secure online transactions. This involves implementing encryption protocols and compliance with industry standards to ensure the security of sensitive customer information during payment processing.
                      </p>
                      <p  className="text-light">Inventory Management: Developing systems to manage product inventory, track stock levels, and update product availability in real-time. This helps businesses to efficiently manage their inventory and prevent overselling or stockouts.
                      </p>
                      <p  className="text-light">Order Management: Building order management systems to track orders, process payments, generate invoices, and manage shipping and delivery logistics. This ensures smooth order processing and fulfillment for both businesses and customers.
                      </p>
                      <p  className="text-light">Security: Implementing robust security measures to protect against cyber threats, data breaches, and fraudulent activities. This includes SSL encryption, PCI compliance, secure authentication, and regular security audits to safeguard customer information and transaction data.
                      </p>
                      <p  className="text-light">Mobile Optimization: Ensuring that the e-commerce website is optimized for mobile devices to provide a seamless shopping experience across different screen sizes and devices. This involves responsive design, mobile-friendly navigation, and optimized performance for mobile users.
                      </p>
                      <p className="text-light mb-4">Overall, e-commerce development aims to create online platforms that not only facilitate transactions but also build trust with customers, drive sales, and support business growth in the digital marketplace.</p>
                     
                  <img className="web-img-set mt-4 mb-4" src="assets/images/service/E-Commerance Development.png" />

                </div>


                <div className="mb-5 pb-5" id="mobile-app">
                  <h1 className="clr-org fw-bold mb-4">Hybrid Mobile App Developer</h1>
                  <p className="text-light mb-4 ">A hybrid mobile app developer is a professional who specializes in creating mobile applications that can run on multiple platforms, such as iOS, Android, and sometimes even web browsers, using a single codebase. Unlike native app development, where separate codebases are required for each platform, hybrid app development leverages web technologies like HTML, CSS, and JavaScript to build cross-platform applications.
                  </p>
                  <p className="text-light">Hybrid app developers typically use frameworks such as Apache Cordova, Ionic, React Native, or Flutter to develop apps that have a native-like user experience across different platforms. These frameworks provide tools and APIs to access native device features, such as camera, GPS, and push notifications, while still allowing developers to write most of the code in web technologies.
                  </p>
                  <p className="text-light">The advantage of hybrid app development is that it enables faster development, easier maintenance, and cost-effectiveness compared to building separate native apps for each platform. However, hybrid apps may not always achieve the same level of performance or user experience as native apps, especially for complex or resource-intensive applications.
                  </p>
                  <p className="text-light mb-4">Overall, hybrid mobile app developers play a crucial role in bridging the gap between web and native app development, enabling businesses to reach a wider audience with their mobile applications while optimizing development time and resources.</p>
                 
                  <img className="web-img-set mt-4 mb-4" src="assets/images/service/Hybrid Mobile App Developer.png" />

                </div>



                <div className="mb-5 pb-5" id="webflow">
                  <h1 className="clr-org text-light fw-bold mb-4">Webflow Development</h1>
                  <p className="mb-4 text-light">Webflow is a powerful visual web design tool that enables users to create responsive websites without writing code. It combines the flexibility of traditional web design with the simplicity of a drag-and-drop interface, allowing designers to build custom websites quickly and efficiently.
                  </p>
                  <p className="text-light">With Webflow, designers can create complex layouts, animations, and interactions visually, without the need for coding skills. It offers a wide range of pre-designed elements and templates, as well as the ability to customize every aspect of a website's design and functionality.
                  </p>
                  <p className="text-light">One of the key features of Webflow is its ability to generate clean, semantic HTML, CSS, and JavaScript code behind the scenes. This means that the websites built with Webflow are not only visually stunning but also optimized for performance and accessibility.
                  </p>
                  <p className="text-light">Webflow also provides hosting and content management capabilities, allowing users to publish and manage their websites directly from the platform. This makes it an all-in-one solution for designers and businesses looking to create and maintain professional websites without the need for technical expertise.
                  </p>
                  <p className="text-light mb-4">In summary, Webflow is a versatile tool that empowers designers to create beautiful, responsive websites with ease, while also providing the flexibility and control needed to bring their creative vision to life.</p>

                  <img className="web-img-set mt-4 mb-4" src="assets/images/service/Webflow Development.png" />
                </div>
 
                <div className="mb-5 " id="forensics">
                <h1 className="clr-org fw-bold mb-4">Digital Forensics</h1>
                <p className="mb-4 text-light">Digital forensics, also known as computer forensics, is a branch of forensic science that deals with the recovery, preservation, analysis, and presentation of digital evidence. It involves investigating digital devices and data to determine what happened, how it happened, and who was involved in various digital incidents, such as cybercrimes, data breaches, hacking, intellectual property theft, and fraud.</p>
                    <p className="text-light">Key aspects of digital forensics include:

                    Evidence Collection: Gathering digital evidence from various sources such as computers, mobile devices, servers, cloud services, and network traffic. This involves creating forensic images of storage devices to preserve the original data and prevent any alterations.</p>
                    <p className="text-light">Evidence Preservation: Ensuring the integrity and authenticity of digital evidence through proper handling, storage, and documentation to maintain its admissibility in legal proceedings.</p>
                    <p className="text-light">Data Recovery: Employing specialized tools and techniques to recover deleted, hidden, or encrypted data from storage devices.</p>
                    <p className="text-light">Analysis: Examining digital evidence to identify relevant information, patterns, and relationships. This may involve examining file metadata, internet history, communication logs, and system logs to reconstruct events and timelines.</p>
                    <p className="text-light">Investigative Techniques: Utilizing various investigative methods such as keyword searching, data carving, timeline analysis, and malware analysis to uncover evidence and gain insights into digital incidents.</p>
                    <p className="text-light">Legal Considerations: Adhering to legal and ethical guidelines throughout the investigation process, including obtaining proper authorization, maintaining chain of custody, and ensuring the privacy rights of individuals.</p>
                    <p className="text-light">Reporting: Documenting findings and conclusions in a clear and comprehensive manner to support legal proceedings, regulatory compliance, or internal investigations.</p>
                    <p className="text-light mb-4">Digital forensics professionals typically have a background in computer science, information technology, or law enforcement. They may work in various sectors, including law enforcement agencies, government organizations, corporate security teams, consulting firms, and legal firms.</p>


                <img className="web-img-set mt-4 mb-4" src="assets/images/service/digital forensics.png" />
              </div>



        </div>

          </div>



        </div>
      </section>


      <section className="service-sec4   ">
      <div className="container padding-set ">
      <div className="">
      <div class="get-in-touch-circle">  <img className="text-center get-in-touch-image mt-3 mx-2  " src="assets/images/service/logo1.png" /></div>
      <p className="fw-bold fs-48 mt-3 text-center text-dark">Get your project off to a flying start</p>
      <p className=" mt-3 text-center  mb-4 pl-4 text-dark">Blockchain implementation is a monumental investment in your business
      longevity, as DLT now turns into one of the most future-proof tech platforms</p>
      <div className="text-center">
      {/*
      <button className="btn btn-primary  get-in-touch-btn px-4  py-2 fs-4 btn-lg mt-3 mb-5">Get in touch</button>
    */}
    <a href="#" type="button" class='butn butn__new'><Link to="/contact" style={{textDecoration:"none", color:"inherit"}}><span>Get in touch</span></Link></a>
      </div>

      </div>
      </div>
    </section>
  

      {/*
      }
    */}


    </>
  )
}

export default Service