import React from 'react'
import "../Footer/Footer.css"
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import { useEffect , useState , useRef} from 'react';




const Footer = () => {

  // const scrollIntoViewForWebsite = () => {
  //   el.scrollIntoView.behavior = "auto"
  //   el.scrollIntoView.block = "auto"

  // }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the threshold as needed
    };

    handleResize(); // Call the function once to set the initial value
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);



  return (
    <div>
      <footer class="footer ">
        <div class="container-fluid px-6">
          <div class="row  footer-row1-set justify-content-between"  >
            <div class="col-lg-3 col-md-6 my-3 mx-lg-2">
              <h3 className='clr-orange fw-bold'>Mission</h3>
              <ul className='list-unstyled'>
              <li className='fs-4 fw-bold'>
              Accelerating Your TechOps for Enhanced Efficiency and Performance. Empowering Your Success with Innovative IT Solutions.
              </li>
              </ul>
            </div>
            <div class="col-lg-2 col-md-6 my-3 mx-lg-4 ">
              <h3 className='clr-orange fw-bold'>Company</h3>
              <ul class="list-unstyled">
                <li className='fs-4'>
                <Link to="/" className="nav-link mt-lg-0 mb-2 " aria-current="page" > Home</Link>                                        
                </li>
                <li className='fs-4'>
                <Link to="/about" className="nav-link mt-lg-0 mb-2  " aria-current="page" > About</Link>                             
                </li>
                <li className='fs-4'>
                <Link to="/service" className="nav-link mt-lg-0 mb-2  " aria-current="page" >Services</Link>                             
                </li>
                <li className='fs-4'>
                <Link to="/product" className="nav-link mt-lg-0 mb-2  " aria-current="page" >Product / Startup</Link>               
                </li>
                <li className='fs-4 mb-1'>
                <Link to="/privacy" className="nav-link mt-lg-0  " aria-current="page" > Privacy Policy</Link>
                </li>
                <li className='fs-4'>
                <Link to="/contact" className="nav-link mt-lg-0 mb-2  " aria-current="page" >Contact</Link>               
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 my-3 " >
              <h3 className='clr-orange fw-bold'>Product / Startup</h3>

              <ul class="list-unstyled d-none d-sm-block d-md-block d-lg-block">
              <HashLink to="/product#spider-ai"  scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}  style={{textDecoration:"none", color:"inherit"}} smooth>

                <li className='fs-4 mb-2 linkcolor '  id="myBtn"  > Spider AI</li>
              </HashLink>
              <HashLink to="/product#just-repair-it" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '   >JustRepairIT</li>
                </HashLink>
                <HashLink to="/product#khana"   scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '   >Khaas Khana</li>

                </HashLink>
                <HashLink to="/product#call-kar" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '     >Call Kar</li>
                </HashLink>
                
                
                <HashLink to="/product#crm"  scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>
                <li className='fs-4 mb-2 linkcolor '   >CRM For All Services</li>

                </HashLink>
                </ul>

                <ul class="list-unstyled  d-block d-sm-none d-md-none d-lg-none">
                <HashLink to="/product#spider-ai"  scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}} smooth>
  
                  <li className='fs-4 mb-2 linkcolor '  id="myBtn"> Spider AI</li>
                </HashLink>
                <HashLink to="/product#just-repair-it" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>
  
                  <li className='fs-4 mb-2 linkcolor '>JustRepairIT</li>
                  </HashLink>
                  <HashLink to="/product#khana"   scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })} style={{textDecoration:"none", color:"inherit"}}  smooth>
  
                  <li className='fs-4 mb-2 linkcolor '>Khaas Khana</li>
  
                  </HashLink>
                  <HashLink to="/product#call-kar" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>
  
                  <li className='fs-4 mb-2 linkcolor '>Call Kar</li>
                  </HashLink>
                  
                  
                  <HashLink to="/product#crm"  scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>
                  <li className='fs-4 mb-2 linkcolor '>CRM For All Services</li>
  
                  </HashLink>
                  </ul>

              {/*
              
              <ul className="list-unstyled text-center d-flex flex-column">
  <HashLink
    to="/product#spider-ai"
    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: isMobile ? 'start' : 'end' })}
    style={{ textDecoration: 'none', color: 'inherit' }}
    smooth
  >
    <li className="fs-4 mb-2 linkcolor" id="myBtn">
      Spider AI
    </li>
  </HashLink>
  <HashLink
    to="/product#just-repair-it"
    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: isMobile ? 'start' : 'end' })}
    style={{ textDecoration: 'none', color: 'inherit' }}
    smooth
  >
    <li className="fs-4 mb-2 linkcolor">JustRepairIT</li>
  </HashLink>
  <HashLink
    to="/product#khana"
    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: isMobile ? 'start' : 'end' })}
    style={{ textDecoration: 'none', color: 'inherit' }}
    smooth
  >
    <li className="fs-4 mb-2 linkcolor">Khaas Khana</li>
  </HashLink>
  <HashLink
    to="/product#call-kar"
    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: isMobile ? 'start' : 'end' })}
    style={{ textDecoration: 'none', color: 'inherit' }}
    smooth
    >
    <li className="fs-4 mb-2 linkcolor">Call Kar</li>
  </HashLink>
  <HashLink
    to="/product#crm"
    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: isMobile ? 'start' : 'end' })}
    style={{ textDecoration: 'none', color: 'inherit' }}
    smooth
  >
    <li className="fs-4 mb-2 linkcolor">CRM For All Services</li>
  </HashLink>
  </ul>
*/}


            </div>
            <div class="col-lg-3 col-md-6 mt-3">
              <h3 className='clr-orange fw-bold'>Services</h3>
              <ul class="list-unstyled d-none d-sm-block d-md-block d-lg-block">
              <HashLink to="/service#web-dev-section" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '  >Web Development</li>
                </HashLink>
                <HashLink to="/service#web-dev-section" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '  >E-Commerce Development</li>
                </HashLink>
                <HashLink to="/service#web-dev-section" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '  >Hybrid Mobile App
                  Development</li>
                  </HashLink>
                  <HashLink to="/service#web-dev-section" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>
                  
                <li className='fs-4 mb-2 linkcolor '  >Webflow Development</li>
                </HashLink>
                <HashLink to="/service#web-dev-section" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>
                  
                <li className='fs-4  linkcolor '  >Digital Forensics</li>
                </HashLink>


                

              </ul>
              <ul class="list-unstyled d-block d-sm-none d-md-none d-lg-none">
              <HashLink to="/service#web-dev-section" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '  >Web Development</li>
                </HashLink>
                <HashLink to="/service#e-commerce" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '  >E-Commerce Development</li>
                </HashLink>
                <HashLink to="/service#mobile-app" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>

                <li className='fs-4 mb-2 linkcolor '  >Hybrid Mobile App
                  Development</li>
                  </HashLink>
                  <HashLink to="/service#webflow" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>
                  
                <li className='fs-4 mb-2 linkcolor '  >Webflow Development</li>
                </HashLink>
                <HashLink to="/service#forensics" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}  style={{textDecoration:"none", color:"inherit"}}  smooth>
                  
                <li className='fs-4  linkcolor '  >Digital Forensics</li>
                </HashLink>


                

              </ul>
            </div>
          </div>
          <hr />
          <div class="row ">
            <div class="col-md-9 my-2">
              <p className=' fs-12rem'>Copyright © 2024 All Rights Reserved Passion by<Link to="/" style={{textDecoration:"none", color:"inherit"}} >  Sankhyank Tech Solutions </Link></p>
            </div>
            <div class="col-md-3 logo-set my-2">
              <ul class="list-inline footer-links">
                <li class="list-inline-item">
                  <a href="#" class="text-white">
                  <a href='https://www.instagram.com/sankhyanktechsolutions/' target='_blank' style={{textDecoration:"none", color:"inherit"}}>
                    <img className='ingredients-logo' src='assets/images/parter-img/insta-icon.png' alt='insta' />
                    </a>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#" class="text-white">
                  <a href='https://www.facebook.com/sankhyanktechsolutions/videos' target='_blank' style={{textDecoration:"none", color:"inherit"}}>
                    <img className='ingredients-logo' src='assets/images/parter-img/fb-icon.png' alt='fb' />
                    </a>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#" class="text-white">
                  <a href='https://www.youtube.com/watch?v=SaZymZuv-WQ' target='_blank' style={{textDecoration:"none", color:"inherit"}}>
                    <img className='ingredients-logo' src='assets/images/parter-img/yt-icon.png' alt='yt' />
                    </a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
  
}


export default Footer