import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Router , Switch, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header'
import Homepage from './Components/HomePage/Homepage';
import Footer from './Components/Footer/Footer';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import Service from './Components/Service/Service';
import Product from './Components/Product/Product';
import Loader from './Components/Loader/Loader';
import ScrollToTop from './Components/ScrollToTop';
import Privacy from './Components/Privacy/Privacy';


function App() {
  return (
    <div className="App">
    {/*
    
  */}
     <ScrollToTop />
    <Header/>
    <Routes>
  
          <Route path="/" exact element={<Homepage />} />
          <Route path="about" element={<About />} />
          <Route path="service" element={<Service />} />
          <Route path="product" element={<Product />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy" element={<Privacy />} />

    </Routes>
    <Footer/>



    </div>
  );
}

export default App;
